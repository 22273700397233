<template>
   <div id="app">
      <router-view></router-view>
      <ScrollItem v-if="activeHeader != 1 && activeDocument == null" 
         :current-page="currentPage" 
         :history-position="historyPosition" 
         @update:current-page="currentPage = $event" 
         @update:history-position="historyPosition = $event"
      />
      <div class="container" @wheel.stop="onScroll" v-if="currentPage != -1 && activeDocument == null" :style="getOverflowStyle()">
         <FirstPage 
            :current-page="currentPage" :active-header="activeHeader" 
            :online="online" 
            :history-position="historyPosition"
            @update:history-position="historyPosition = $event"
            @update:current-page="currentPage = $event" 
            @update:active-header="activeHeader = $event"
            @update:open-burgerMenu="hideScroll = $event"
         />
         <SecondPage :currentPage="currentPage"/>
         <ThreePage :currentPage="currentPage"/>
         <FourPage :currentPage="currentPage"/>
         <FivePage 
            :current-page="currentPage" :active-header="activeHeader" :check-for-scroll="checkForScroll" :active-document="activeDocument"
            @update:current-page="currentPage = $event" 
            @update:active-header="activeHeader = $event"
            @update:check-for-scroll="checkForScroll  = $event"
            @update:active-document="activeDocument = $event" 
         />
      </div>
      <DonatePage 
         :active-header="activeHeader" :current-page="currentPage" :history-position="historyPosition"  :active-document="activeDocument"
         @update:current-page="currentPage = $event" 
         @update:active-header="activeHeader = $event" 
         @update:history-position="historyPosition = $event"
         @update:open-burgerMenu="hideScroll = $event"
         @update:active-document="activeDocument = $event" 
      />
      <DocumentPage v-if="activeDocument != null" 
         :active-document="activeDocument" :current-page="currentPage" :history-position="historyPosition" :active-header="activeHeader"
         @update:active-document="activeDocument = $event" 
         @update:current-page="currentPage = $event" 
         @update:history-position="historyPosition = $event"
         @update:active-header="activeHeader = $event" 
      />
   </div>
</template>

<script>
import FirstPage from './components/FirstPage.vue';
import ScrollItem from './components/ScrollItem.vue';
import SecondPage from './components/SecondPage.vue';
import ThreePage from './components/ThreePage.vue';
import FourPage from './components/FourPage.vue';
import FivePage from './components/FivePage.vue';
import DonatePage from './components/DonatePage.vue';
import DocumentPage from './components/DocumentPage.vue';

export default {
   name: 'App',
   data() {
      return {
         currentPage: 0,
         activeHeader: -1,
         activeDocument: null,
         online: {count: 0, interval: null},
         check: 1,
         historyPosition: 0,
         checkForScroll: false,
         hideScroll: true,
      }
   },
   components: {
      ScrollItem,
      FirstPage,
      SecondPage,
      ThreePage,
      FourPage,
      FivePage,
      DonatePage,
      DocumentPage
   },
   methods: {
      onScroll(event) {
         if (this.scrolling || this.checkForScroll || !this.hideScroll) return;
         if (event.deltaY > 0) {
            if (this.currentPage < 4) {
               this.currentPage++;
               this.scrolling = true;
               this.activeIndex = null;
               setTimeout(() => {
                  this.scrolling = false;
               }, 500)
               const anim = document.querySelector('.container').animate(
               [
                  { transform: `translateY(${this.historyPosition}vh)`},
                  { transform: `translateY(${this.historyPosition - 100}vh)`},
               ], 
               {
                  duration: 400,
                  iterations: 1,
                  easing: 'ease-in-out',
                  effect: 'spring',
                  spring: { mass: 1, stiffness: 320, damping: 40 },
               }
               )
               anim.addEventListener('finish', () => {
                  this.historyPosition -= 100;
                  document.querySelector('.container').style.transform = `translateY(${this.historyPosition}vh)`;
               })
            }
            
         } else {
            if (this.currentPage > 0) {
               this.currentPage--;
               this.scrolling = true;
               this.activeIndex = null;
               setTimeout(() => {
                  this.scrolling = false;
               }, 500)
               const anim = document.querySelector('.container').animate(
               [
                  { transform: `translateY(${this.historyPosition}vh)`},
                  { transform: `translateY(${this.historyPosition + 100}vh)`},
               ], 
               {
                  duration: 400,
                  iterations: 1,
                  easing: 'ease-in-out',
                  effect: 'spring',
                  spring: { mass: 1, stiffness: 320, damping: 40 },
               }
               )
               anim.addEventListener('finish', () => {
                  this.historyPosition += 100;
                  document.querySelector('.container').style.transform = `translateY(${this.historyPosition}vh)`;
               })
            }
         }

         // не забути прибрати інтервал, коли переходиш на донат
         if(this.currentPage != 0) clearInterval(this.online.interval);
         else{
            this.setOnline();
            this.online.interval = setInterval(() => {
               this.setOnline();
            }, 30000);
         } 
      },
      WidthUpdate() {
         if (window.innerWidth <= 744) this.hideScroll = false;
      },
      getOverflowStyle() {
         if (!this.hideScroll) {
            return {
               overflowY: 'scroll',
            };  
         }
      },
      preloadImages() {
         const imageUrls = [
            'http://localhost:8080/img/donate.9fe60eff.jpg',
            // require('@http://localhost:8080/img/donate.9fe60eff.jpg'),
            require('@/assets/img/back/slide4-1.jpg'),
            require('@/assets/img/back/slide4-2.jpg'),
            require('@/assets/img/back/slide4-3.jpg'),
            require('@/assets/img/back/donate.jpg'),
         ];

         imageUrls.forEach(url => {
            const image = new Image();
            image.src = url;
         });
       
      },
      setOnline(){
         fetch("https://cdn.rage.mp/master/").then((response) => response.json())
         .then((data) => {
           this.online.count = data["game.secondliferp.com.ua:22005"].players;
         })
         .catch((error) => {
           console.error(error);
         });
      }
   },

   created(){
      this.setOnline();
      this.online.interval = setInterval(() => {
         this.setOnline();
      }, 30000);
      this.preloadImages();
      window.addEventListener("resize", this.WidthUpdate);
   },
   mounted() {
      this.WidthUpdate();
   },
   watch: {
   '$route.path': function(newPath) {
      if (newPath === '/politika_konfidenchiynosti') {
         this.currentPage = -1;
         this.activeDocument = 0;
      } else if(newPath === '/dogovir/zrazok_publichnoho_dohovoru_oferty') {
         this.currentPage = -1;
         this.activeDocument = 1;
      } else if(newPath === '/pravila_koristyvannya_saitom') {
         this.currentPage = -1;
         this.activeDocument = 2;
      }
   },
},
}
</script>

<style lang="scss">
#app  {
   background: #000;
}
.container {

   height: 100vh;
   width: 100vw;
   &.container::-webkit-scrollbar {
      width: 0;
      height: 0;
   }
   &.container {
      scrollbar-width: none;
   }
}
</style>
