import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  { path: '/politika_konfidenchiynosti', component: () => import('./components/DocumentPage.vue') },
  { path: '/dogovir/zrazok_publichnoho_dohovoru_oferty', component: () => import('./components/DocumentPage.vue') },
  { path: '/pravila_koristyvannya_saitom', component: () => import('./components/DocumentPage.vue') },
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;