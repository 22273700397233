<template>
   <div class="second__screen">
      <main>
         <!-- <div class="block" id="bl"></div> -->
         <div class="font">
            <div class="text">
               <h2>SecondLife</h2>
               <h3>
                  – це новий GTAV RP сервер, створений повністю з нуля українцями для
                  українців.
               </h3>
               <h4>
                  Обіцяємо – вам неодмінно <br> сподобається! Ми подаруємо <br> вам купу
                  приємних емоцій та <br> цікавого геймплею, а комусь <br> навіть власне «друге
                  життя».
               </h4>
            </div>
            <img src="../assets/img/slide2/pic copy.webp"  alt="helicopter" class="helicopter" draggable="false">
         </div>
         <!-- <div class="block"></div> -->
      </main>
   </div>
</template>
<script>
export default {
   name: "SecondPage",
   props: {
      currentPage: {
         type: Number,
         required: true,
      },
   },


};
</script>
<style lang="scss">
@import './nulstyle.scss';
@import './variables.scss';
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
.second__screen {
   height: 100vh;
   width: 100vw;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   background: url(../assets/img/back/slide2.jpg) no-repeat center center;
   background-size: cover;
   @media (max-width: 744px) {
      background: url(../assets/img/mobile/back/slide2.jpg) no-repeat center center;
      background-size: cover;
      overflow-x: hidden;
   }
   @media (max-width: 400px) { 
      height: auto;
   }
   
   & > main {
      height: get-vh(680px);
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: get-vh(1920px);
      width: calc(100vw - #{get-vh(192px)});
      @media (max-width: 744px) { 
         width: calc(100vw - #{get-vh(64px)});
      }
      @media (max-width: 400px) { 
         width: calc(100vw - #{get-vh(48px)});
      }
      & > .font {
         z-index: 2;
         width: get-vh(1600px);
         height: get-vh(680px);
         display: flex;
         align-items: center;
         justify-content: space-between;
         @media (max-width: 1440px) {
            height: get-vh(544px);
            width: get-vh(1280px);
         }
         @media (max-width: 1180px) {
            height: get-vh(446px);
            width: get-vh(1060px);
         }
         @media (max-width: 960px) {
            height: get-vh(361px);
            width: get-vh(858px);
         }
         @media (max-width: 744px) {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            text-align: center;
            height: get-vh(855px);
            width: get-vh(528px);
         }
         @media (max-width: 400px) {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            text-align: center;
            height: get-vh(570px);
            width: get-vh(352px);
         }
         & > .text {
            width: get-vh(591px);
            height: get-vh(680px);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            @media (max-width: 1440px) {
               width: get-vh(488px);
               height: get-vh(544px);
            }  
            @media (max-width: 1180px) {
               height: get-vh(400px);
               width: get-vh(400px);
            }
            @media (max-width: 960px) {
               height: get-vh(324px);
               width: get-vh(310px);
            }
            @media (max-width: 744px) {
               height: get-vh(423px);
               width: 100%;
            }
            @media (max-width: 400px) {
               height: get-vh(282px);
               width: 100%;
            }
            & > h2 {
               font-weight: 700;
               font-size: get-vh(100px);
               line-height: 125%;
               letter-spacing: -0.03em;
               color: #ffffff;
               @media (max-width: 1440px) {
                  font-size: get-vh(84px);
               }
               @media (max-width: 1180px) {
                  font-size: get-vh(68px);
               }
               @media (max-width: 960px) {
                  font-size: get-vh(55px);
               }
               @media (max-width: 744px) {
                  font-size: get-vh(75px);
               }
               @media (max-width: 400px) {
                  font-size: get-vh(50px);
               }
            }
            & > h3 {
               font-style: italic;
               font-weight: 700;
               font-size: get-vh(42px);
               line-height: 130%;
               display: flex;
               align-items: center;
               letter-spacing: -0.05em;
               @media (max-width: 1440px) {
                  font-size: get-vh(35px);
               }
               @media (max-width: 1180px) {
                  font-size: get-vh(28px);
               }
               @media (max-width: 960px) {
                  font-size: get-vh(22px);
               }
               @media (max-width: 744px) {
                  font-size: get-vh(33px);
               }
               @media (max-width: 400px) {
                  font-size: get-vh(22px);
               }
            }
            & > h4 {
               width: get-vh(576px);
               font-weight: 500;
               font-size: get-vh(32px);
               line-height: 130%;
               letter-spacing: -0.03em;
               @media (max-width: 1440px) {
                  width: 100%;
                  font-size: get-vh(27px);
               }
               @media (max-width: 1180px) {
                  width: 100%;
                  font-size: get-vh(22px);
               }
               @media (max-width: 960px) {
                  width: 100%;
                  font-size: get-vh(18px);
               }
               @media (max-width: 744px) {
                  font-size: get-vh(27px);
               }
               @media (max-width: 400px) {
                  font-size: get-vh(18px);
               }
            }
         }
         & > .helicopter {
            width: get-vh(920px);
            height: 100%;
            filter: drop-shadow(0px get-vh(10px) get-vh(64px) rgba(0, 0, 0, 0.3));
            @media (max-width: 1440px) {
               width: get-vh(736px);
            }
            @media (max-width: 1180px) {
               width: get-vh(604px);
            }
            @media (max-width: 960px) {
               width: get-vh(490px);
            }
            @media (max-width: 744px) {
               width: 100%;
               height: get-vh(390px);
               border-radius: get-vh(36px);
               filter: drop-shadow(0px 0px get-vh(96px) rgba(0, 0, 0, 0.25));
            }
            @media (max-width: 400px) {
               width: 100%;
               height: get-vh(260px);
               border-radius: get-vh(24px);
               filter: drop-shadow(0px 0px get-vh(64px) rgba(0, 0, 0, 0.25));
            }
         }
      }
   }
}
</style>