<template>
   <div class="three__screen">
      <div class="font">
         <h2>На цьому сервері ви зможете:</h2>
         <div class="tiles">
            <div class="tile" v-for="tile in 13" :key="tile"></div>
         </div>
         <h3>…та багато іншого</h3>
      </div>
   </div>
</template>
<script>
export default {
   name: "ThreePage",
   props: {
      currentPage: {
         type: Number,
         required: true,
      },
   },
   data() {
      return {
      };
   },
};
</script>
<style lang="scss">
@import './nulstyle.scss';
@import './variables.scss';
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
$mouse-device: '(hover: hover) and (pointer: fine)';
.three__screen {
   height: 100vh;
   width: 100vw;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   background: url(../assets/img/back/slide3.jpg) no-repeat center center;
   background-size: cover;
   @media (max-width: 744px) {
      background: url(../assets/img/mobile/back/slide3.jpg) no-repeat center center;
      background-size: cover;
      overflow-x: hidden;
      height: auto;
   }
   & > .font {
      width: calc(100vw - #{get-vh(96px)});
      z-index: 1;
      width: get-vh(1600px);
      height: get-vh(880px);
      display: flex;
      align-items: center;
      flex-direction: column;
      @media (max-width: 1440px) {
         width: get-vh(1280px);
         height: get-vh(704px);
      }
      @media (max-width: 1180px) {
         width: get-vh(1024px);
         height: get-vh(564px);
      }
      @media (max-width: 960px) {
         width: get-vh(830px);
         height: get-vh(564px);
      }
      @media (max-width: 744px) {
         width: get-vh(648px);
         height: auto;
      }
      & > h2 {
         font-weight: 700;
         font-size: get-vh(64px);
         line-height: 125%;
         letter-spacing: -0.03em;
         color: #ffffff;
         @media (max-width: 1440px) {
            font-size: get-vh(51px);
         }
         @media (max-width: 1180px) {
            font-size: get-vh(40px);
         }
         @media (max-width: 960px) {
            font-size: get-vh(34px);
         }
         @media (max-width: 744px) {
            margin-top: get-vh(96px);
            text-align: center;
            font-size: get-vh(48px);
         }
         @media (max-width: 400px) {
            margin-top: get-vh(64px);
            text-align: center;
            font-size: get-vh(32px);
         }
      }
      & > .tiles {
         margin-top: get-vh(44px);
         width: get-vh(1600px);
         height: get-vh(680px);
         display: grid;
         grid-template-columns: repeat(6, 1fr);
         grid-template-rows: repeat(3, 1fr);
         gap: get-vh(24px);
         @media (max-width: 1440px) {
            width: get-vh(1280px);
            height: get-vh(544px);
            gap: get-vh(19px);
         }
         @media (max-width: 1180px) {
            width: get-vh(1024px);
            height: get-vh(436px);
            gap: get-vh(15px);
         }
         @media (max-width: 960px) {
            width: get-vh(830px);
            height: get-vh(353px);
            gap: get-vh(12px);
         }
         & > .tile:nth-child(1) {
            width: get-vh(247px);
            height: get-vh(211px);
            grid-area: 1 / 1 / 2 / 2;
            background: url("../assets/img/slide3/poshtaBlur.webp");
            background-size: cover;
            transition: background 0.3s ease-in-out;
            &:hover {
               @media #{$mouse-device} {
                  background: url("../assets/img/slide3/poshta.webp");
                  background-size: cover;
               }
            }
            @media (max-width: 1440px) {
               width: get-vh(198px);
               height: get-vh(168px);
            }
            @media (max-width: 1180px) {
               width: get-vh(158px);
               height: get-vh(134px);
            }
            @media (max-width: 960px) {
               width: get-vh(128px);
               height: get-vh(108px);
            }
         }
         & > .tile:nth-child(2) {
            transition: background 0.3s ease-in-out;
            grid-area: 2 / 1 / 3 / 2;
            background-size: cover;
            background-image: url("../assets/img/slide3/voiceBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/voice.webp");
                  background-size: cover;
               }
            }
         }
         & > .tile:nth-child(3) {
            transition: background 0.3s ease-in-out;
            grid-area: 3 / 1 / 4 / 2;
            background-size: cover;
            background-image: url("../assets/img/slide3/arenaBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/arena.webp");
                  background-size: cover;
               }
            }
         }
         & > .tile:nth-child(4) {
            transition: background 0.3s ease-in-out;
            grid-area: 1 / 2 / 3 / 3;
            width: get-vh(246px);
            height: get-vh(445px);
            background-size: cover;
            background-image: url("../assets/img/slide3/bandBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/band.webp");
                  background-size: cover;
               }
            }
            @media (max-width: 1440px) {
               width: get-vh(198px);
               height: get-vh(356px);
            }
            @media (max-width: 1180px) {
               width: get-vh(158px);
               height: get-vh(284px);
            }
            @media (max-width: 960px) {
               width: get-vh(128px);
               height: get-vh(230px);
            }
         }
         & > .tile:nth-child(5) {
            transition: background 0.3s ease-in-out;
            grid-area: 3 / 2 / 4 / 4;
            width: get-vh(517px);
            height: get-vh(211px);
            background-size: cover;
            background-image: url("../assets/img/slide3/carsBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/cars.webp");
                  background-size: cover;
               }
            }
            @media (max-width: 1440px) {
               width: get-vh(414px);
               height: get-vh(168px);
            }
            @media (max-width: 1180px) {
               width: get-vh(332px);
               height: get-vh(134px);
            }
            @media (max-width: 960px) {
               width: get-vh(267px);
               height: get-vh(110px);
            }
         }
         & > .tile:nth-child(6) {
            transition: background 0.3s ease-in-out;
            grid-area: 1 / 3 / 2 / 4;
            background-size: cover;
            background-image: url("../assets/img/slide3/avatarBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/avatar.webp");
                  background-size: cover;
               }
            }
         }
         & > .tile:nth-child(7) {
            transition: background 0.3s ease-in-out;
            grid-area: 1 / 4 / 2 / 6;
            width: get-vh(518px);
            height: get-vh(210px);
            background-size: cover;
            background-image: url("../assets/img/slide3/houseBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/house.webp");
                  background-size: cover;
               }
            }
            @media (max-width: 1440px) {
               width: get-vh(414px);
               height: get-vh(168px);
            }
            @media (max-width: 1180px) {
               width: get-vh(332px);
               height: get-vh(134px);
            }
            @media (max-width: 960px) {
               width: get-vh(269px);
               height: get-vh(108px);
            }
         }
         & > .tile:nth-child(8) {
            transition: background 0.3s ease-in-out;
            grid-area: 1 / 6 / 2 / 7;
            background-size: cover;
            background-image: url("../assets/img/slide3/govBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/gov.webp");
                  background-size: cover;
               }
            }
         }
         & > .tile:nth-child(9) {
            transition: background 0.3s ease-in-out;
            grid-area: 2 / 3 / 3 / 5;
            width: get-vh(517px);
            height: get-vh(211px);
            background-size: cover;
            background-image: url("../assets/img/slide3/friendsBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/friends.webp");
                  background-size: cover;
               }
            }
            @media (max-width: 1440px) {
               width: get-vh(414px);
               height: get-vh(168px);
            }
            @media (max-width: 1180px) {
               width: get-vh(332px);
               height: get-vh(134px);
            }
            @media (max-width: 960px) {
               width: get-vh(269px);
               height: get-vh(108px);
            }
         }
         & > .tile:nth-child(10) {
            transition: background 0.3s ease-in-out;
            grid-area: 2 / 5 / 4 / 6;
            width: get-vh(246px);
            height: get-vh(445px);
            background-size: cover;
            background-image: url("../assets/img/slide3/businessBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/business.webp");
                  background-size: cover;
               }
            }
            @media (max-width: 1440px) {
               width: get-vh(196px);
               height: get-vh(356px);
            }
            @media (max-width: 1180px) {
               width: get-vh(156px);
               height: get-vh(284px);
            }
            @media (max-width: 960px) {
               width: get-vh(126px);
               height: get-vh(232px);
            }
         }
         & > .tile:nth-child(11) {
            transition: background 0.3s ease-in-out;
            grid-area: 3 / 4 / 4 / 5;
            background-size: cover;
            background-image: url("../assets/img/slide3/casinoBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/casino.webp");
                  background-size: cover;
               }
            }
         }
         & > .tile:nth-child(12) {
            transition: background 0.3s ease-in-out;
            grid-area: 2 / 6 / 3 / 7;
            background-size: cover;
            background-image: url("../assets/img/slide3/eventsBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/events.webp");
                  background-size: cover;
               }
            }
         }
         & > .tile:nth-child(13){
            transition: background 0.3s ease-in-out;
            grid-area: 3 / 6 / 4 / 7;
            background-size: cover;
            background-image: url("../assets/img/slide3/policeBlur.webp");
            &:hover {
               @media #{$mouse-device} {
                  background-image: url("../assets/img/slide3/police.webp");
                  background-size: cover;
               }
            }
         }
         @media (max-width: 744px) {
            width: get-vh(648px);
            height: get-vh(1141px);
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(6, 1fr);
            gap: get-vh(19px);
            & > .tile:nth-child(1) {
               width: get-vh(425px);
               height: get-vh(174px);
               grid-area: 1 / 1 / 2 / 3;
               background-image: url("../assets/img/slide3/friends.webp");
               background-size: cover;
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(2) {
               width: get-vh(203px);
               height: get-vh(174px);
               grid-area: 1 / 3 / 2 / 4;
               background-size: cover;
               background-image: url("../assets/img/slide3/avatar.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(3) {
               width: get-vh(203px);
               height: get-vh(174px);
               grid-area: 2 / 1 / 3 / 2;
               background-size: cover;
               background-image: url("../assets/img/slide3/poshta.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(4) {
               width: get-vh(203px);
               height: get-vh(367px);
               grid-area: 2 / 2 / 4 / 3;
               background-size: cover;
               background-image: url("../assets/img/slide3/band.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(5) {
               width: get-vh(203px);
               height: get-vh(174px);
               grid-area: 2 / 3 / 3 / 4;
               background-size: cover;
               background-image: url("../assets/img/slide3/events.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(6) {
               width: get-vh(203px);
               height: get-vh(174px);
               grid-area: 3 / 1 / 4 / 2;
               background-size: cover;
               background-image: url("../assets/img/slide3/voice.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(7) {
               width: get-vh(203px);
               height: get-vh(174px);
               grid-area: 3 / 3 / 4 / 4;
               background-size: cover;
               background-image: url("../assets/img/slide3/arena.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(8) {
               width: get-vh(203px);
               height: get-vh(367px);
               grid-area: 4 / 1 / 6 / 2;
               background-size: cover;
               background-image: url("../assets/img/slide3/business.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(9) {
               height: get-vh(174px);
               width: get-vh(425px);
               grid-area: 4 / 2 / 5 / 4;
               background-size: cover;
               background-image: url("../assets/img/slide3/house.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(10) {
               width: get-vh(203px);
               height: get-vh(174px);
               grid-area: 5 / 2 / 6 / 3;
               background-size: cover;
               background-image: url("../assets/img/slide3/police.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(11) {
               width: get-vh(203px);
               height: get-vh(174px);
               grid-area: 5 / 3 / 6 / 4;
               background-size: cover;
               background-image: url("../assets/img/slide3/gov.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(12) {
               width: get-vh(203px);
               height: get-vh(174px);
               grid-area: 6 / 1 / 7 / 2;
               background-size: cover;
               background-image: url("../assets/img/slide3/casino.webp");
               border-radius: get-vh(19px);
            }
            & > .tile:nth-child(13) {
               width: get-vh(425px);
               height: get-vh(174px);
               grid-area: 6 / 2 / 7 / 4;
               background-size: cover;
               background-image: url("../assets/img/slide3/cars.webp");
               border-radius: get-vh(19px);
            }
         }
         @media (max-width: 400px) {
            width: get-vh(352px);
            height: get-vh(1424px);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(9, 1fr);
            gap: get-vh(16px);
            & > .tile:nth-child(1) {
               width: 100%;
               height: get-vh(144px);
               grid-area: 1 / 1 / 2 / 3;
               background-image: url("../assets/img/slide3/friends.webp");
               background-size: cover;
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(2) {
               width: get-vh(168px);
               height: get-vh(144px);
               grid-area: 2 / 1 / 3 / 2;
               background-size: cover;
               background-image: url("../assets/img/slide3/avatar.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(3) {
               width: get-vh(168px);
               height: get-vh(144px);
               grid-area: 2 / 2 / 3 / 3;
               background-size: cover;
               background-image: url("../assets/img/slide3/events.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(4) {
               width: get-vh(168px);
               height: get-vh(144px);
               grid-area: 3 / 1 / 4 / 2;
               background-size: cover;
               background-image: url("../assets/img/slide3/poshta.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(5) {
               width: get-vh(168px);
               height: get-vh(304px);
               grid-area: 3 / 2 / 5 / 3;
               background-size: cover;
               background-image: url("../assets/img/slide3/band.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(6) {
               width: get-vh(168px);
               height: get-vh(144px);
               grid-area: 4 / 1 / 5 / 2;
               background-size: cover;
               background-image: url("../assets/img/slide3/voice.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(7) {
               width: 100%;
               height: get-vh(144px);
               grid-area: 5 / 1 / 6 / 3;
               background-size: cover;
               background-image: url("../assets/img/slide3/house.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(8) {
               width: get-vh(168px);
               height: get-vh(304px);
               grid-area: 6 / 1 / 8 / 2;
               background-size: cover;
               background-image: url("../assets/img/slide3/business.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(9) {
               width: get-vh(168px);
               height: get-vh(144px);
               grid-area: 6 / 2 / 7 / 3;
               background-size: cover;
               background-image: url("../assets/img/slide3/arena.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(10) {
               width: get-vh(168px);
               height: get-vh(144px);
               grid-area: 7 / 2 / 8 / 3;
               background-size: cover;
               background-image: url("../assets/img/slide3/casino.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(11) {
               width: get-vh(168px);
               height: get-vh(144px);
               grid-area: 8 / 1 / 9 / 2;
               background-size: cover;
               background-image: url("../assets/img/slide3/police.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(12) {
               width: get-vh(168px);
               height: get-vh(144px);
               grid-area: 8 / 2 / 9 / 3;
               background-size: cover;
               background-image: url("../assets/img/slide3/gov.webp");
               border-radius: get-vh(16px);
            }
            & > .tile:nth-child(13) {
               width: 100%;
               height: get-vh(144px);
               grid-area: 9 / 1 / 10 / 3;
               background-size: cover;
               background-image: url("../assets/img/slide3/cars.webp");
               border-radius: get-vh(16px);
            }
         }
      }
      & > h3 {
         margin-top: get-vh(32px);
         font-weight: 700;
         font-size: get-vh(36px);
         line-height: 125%;
         display: flex;
         align-items: center;
         text-align: center;
         letter-spacing: -0.03em;
         @media (max-width: 1440px) {
            font-size: get-vh(28px);
         }
         @media (max-width: 1180px) {
            font-size: get-vh(22px);
         }
         @media (max-width: 744px) {
            margin-top: get-vh(36px);
            margin-bottom: get-vh(97px);
            font-size: get-vh(36px);
         }
         @media (max-width: 400px) {
            margin-top: get-vh(24px);
            margin-bottom: get-vh(65px);
            font-size: get-vh(24px);
         }
      }
   }
}
</style>