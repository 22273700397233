<template >
   <div class="scrolldonate" :style="getOverflowStyle()">
      <div class="donate" v-if="activeHeader === 1" >
         <header>
            <div class="container__hed">
               <div class="logo" @click="ClickPage(-1,0)" v-if="burgerMenu == 0">
                  <img src="../../src/assets/img/logoMini.png" alt="Logo" draggable="false">
               </div>
               <div class="logo" @click="$emit('update:active-header', -1); $emit('update:current-page', 0); $emit('update:open-burgerMenu', false);" v-else>
                  <img src="../../src/assets/img/logoMini.png" alt="Logo" draggable="false">
               </div>
               <div class="links" v-if="burgerMenu == 0">
                  <ul class="page">
                     <li @click="ClickPage(-1,4)">FAQ</li>
                     <li><a href="https://forum.secondliferp.com.ua/index.php" target="_blank">Форум</a></li>
                     <li :style="activeHeader === 1 ? 'color: #FFB800;' : 'color: #FFFFFF;'" @click="$emit('update:active-header', -1); $emit('update:current-page', 2)">Донат</li>
                  </ul>
                  <ul class="social-icons">
                     <li><a href="https://discord.gg/secondlife-roleplay-960651717156937728" target="_blank"><img src="../../src/assets/img/discrod.svg" alt="discrod" draggable="false"></a></li>
                     <li><a href="https://t.me/SecondLifeRP" target="_blank"><img src="../../src/assets/img/telegram.svg" alt="telegram" draggable="false"></a></li>
                     <li><a href="https://www.youtube.com/@SecondLifeRP_UA" target="_blank"><img src="../../src/assets/img/youtube.svg" alt="yt" draggable="false"></a></li>
                     <li><a href="https://www.instagram.com/secondliferp_ua" target="_blank"><img src="../../src/assets/img/instagram.svg" alt="inst" draggable="false"></a></li>
                     <li><a href="https://www.tiktok.com/@secondliferp_ua" target="_blank"><img src="../../src/assets/img/tiktok.svg" alt="tiktok" draggable="false"></a></li>
                  </ul>
               </div>
               <img src="../assets/img/menu.svg" alt="" class="burger" v-if="burgerMenu == 1" @click="() => {$emit('update:open-burgerMenu', true);openBurgerMenu = true; burgerMenu = 2}">
               <img src="../assets/img/close.svg" alt="" class="burger" v-if="burgerMenu == 2" @click="() => {$emit('update:open-burgerMenu', false);openBurgerMenu = false; burgerMenu = 1}">
            </div>
         </header>
         <main>
            <div class="font">
               <div class="toup__left">
                  <div class="left__header">Поповнити баланс</div>
                  <div class="wrapper__countbonus">
                     <div class="countbonus__text">Отримайте бонус <br>при поповненні<br>балансу від ₴2500</div>
                     <div class="wrapper__procentbonus">
                        <div class="item__procentbonus" :class="{active: money.slice(1) >= 2500 && money.slice(1) <= 4999}">
                           <div class="title__procent">+5%</div>
                           <div class="subtitle__bonus">від<br>₴2.5К</div>
                        </div>
                        <div class="sep"></div>
                        <div class="item__procentbonus" :class="{active: money.slice(1) >= 5000 && money.slice(1) <= 9999}">
                           <div class="title__procent">+7%</div>
                           <div class="subtitle__bonus">від<br>₴5К</div>
                        </div>
                        <div class="sep"></div>
                        <div class="item__procentbonus" :class="{active: money.slice(1) >= 10000 && money.slice(1) <= 19999}">
                           <div class="title__procent">+10%</div>
                           <div class="subtitle__bonus">від<br>₴10К</div>
                        </div>
                        <div class="sep"></div>
                        <div class="item__procentbonus" :class="{active: money.slice(1) >= 20000}">
                           <div class="title__procent">+12%</div>
                           <div class="subtitle__bonus">від<br>₴20К</div>
                        </div>
                     </div>
                  </div>
                  <div class="wrapper__toup">
                     <input type="text" class="login" placeholder="Вкажіть ваш логін" v-model="login" :class="{incorrect: incorrectLogin}">
                     <input type="text" class="left__countmoney" placeholder="₴4444444" v-model="money" maxlength="8" :class="{incorrect: incorrectMoney}"
                        @input="(e) => {
                           money = money.replace(/[^0-9]/g, '');
                           if(money[0] == 0) {
                              money = '';
                           } else if (money.length >= 1) {
                              money = '₴' + money;
                           }
                           CountPercent(money.slice(1))
                        }"
                     >
                  </div>
                  <div class="wrapper__course">
                     <div class="left__course">
                        1 грн = 1
                        <img src="../assets/img/slcoin.svg" alt="" class="icon-coin">
                     </div>
                     <div class="right__part">
                        <div class="count__taken">
                           <div class="count__take">Ви отримаєте: &nbsp;</div>
                           {{ money == '' ? 0 : Math.trunc(Number(money.slice(1)) + Number(percentAdd))}}&nbsp; <img src="../assets/img/slcoin.svg" alt="" class="icon-coin">
                        </div>
                        <div class="activat__bonus" v-if="money.slice(1) >= 2500">
                           Бонус {{ money.slice(1) >= 2500 && money.slice(1) <= 4999 ? 5 : money.slice(1) >= 5000 && money.slice(1) <= 9999 ? 7 : money.slice(1) >= 10000 && money.slice(1) <= 19999 ? 10 : money.slice(1) >= 20000 ? 12 : '' }}% враховано до суми
                        </div>
                     </div>
                  </div>
                  <div class="left__agreement" :class="{margin: money.slice(1) >= 2500}">
                     <div class="circle__accept" @click="secondCheck = !secondCheck">
                        <img src="../assets/img/check.svg" alt="" class="icon-check" v-show="secondCheck">
                     </div>
                     Я погоджуюся з умовами платіжної<br> системи та користувацькою угодою
                  </div>
                  <button class="left__buy" :class="{active: login.length >= 1 && money.slice(1).length >= 1 && secondCheck, incorrect: incorrectLogin}" @click="PaymentsFondy()">Перейти до оплати</button>
               </div>
               <div class="side__two">
                  <img src="../assets/img/donate/pikachu.webp" alt="" class="top__img" draggable="false">
                  <div class="text">За допомогою LifeCoins ви можете придбати різний цифровий контент, зокрема: унікальний транспорт, додаткові аксесуари для ігрового персонажа, ігрову валюту, розширення гаражного простору тощо.</div>
                  <div class="text">Якщо у вас виникли проблеми з придбанням або ви отримали помилку при оплаті, будь ласка, зверніться до адміністрації за допомогою пошти <span class="yellow">secondlifeukraine@gmail.com</span> або у <span class="yellow">Discord</span> за допомогою тікету.</div>
                  <div class="text">Будьте уважні - адміністрація ніколи не вимагатиме у вас конфіденційних даних платіжних методів, таких як CVV та PIN. </div>
               </div>
            </div>
         </main> 
         <footer v-show="burgerMenu == 1 || burgerMenu == 2">
            <div class="foot__containermin">
               <img src="../../src/assets/img/logoMini.png" alt="Logo" draggable="false" class="logo__text">
               <div class="few__text">
                  <div class="item" @click="$emit('update:active-header', -1); $emit('update:current-page', 0); $emit('update:open-burgerMenu', false);">Повернутися на головну</div> <div class="sep"></div>
                  <div class="item"><a href="https://forum.secondliferp.com.ua/index.php" target="_blank">Форум</a></div>
               </div>
               <div class="media">
                  <ul class="social-icons">
                     <li><a href="https://discord.gg/secondlife-roleplay-960651717156937728" target="_blank"><img src="../../src/assets/img/discrod.svg" alt="discrod" draggable="false"></a></li>
                     <li><a href="https://t.me/SecondLifeRP" target="_blank"><img src="../../src/assets/img/telegram.svg" alt="telegram" draggable="false"></a></li>
                     <li><a href="https://www.youtube.com/@SecondLifeRP_UA" target="_blank"><img src="../../src/assets/img/youtube.svg" alt="yt" draggable="false"></a></li>
                     <li><a href="https://www.instagram.com/secondliferp_ua" target="_blank"><img src="../../src/assets/img/instagram.svg" alt="inst" draggable="false"></a></li>
                     <li><a href="https://www.tiktok.com/@secondliferp_ua" target="_blank"><img src="../../src/assets/img/tiktok.svg" alt="tiktok" draggable="false"></a></li>
                  </ul>
               </div>
               <div class="maney__words">
                  <div class="item"><a href="mailto:secondlifeukraine@gmail.com">E-mail</a></div>
                  <div class="item"><router-link to="/politika_konfidenchiynosti">Політика конфіденційності</router-link></div>
                  <div class="item"><router-link to="/dogovir/zrazok_publichnoho_dohovoru_oferty">Користувацька угода</router-link></div>
                  <div class="item"><router-link to="/pravila_koristyvannya_saitom">Правила користування сайтом</router-link></div>
               </div>
               <div class="mini__textlover">
                  © 2024 SecondLife RP. Всі права захищені. <br>
                  <span>
                     SecondLife RolePlay не підтримується та не пов'язана з Take-Two,
                     Rockstar North Interactive або іншими правовласниками.
                     Усі використані товарні знаки належать їх відповідним власникам.
                  </span>
               </div>
            </div>
         </footer>
         <div class="open__burgermenu" v-if="openBurgerMenu">
            <div class="list__social">
               <div class="item" @click="$emit('update:active-header', -1); $emit('update:current-page', 0); $emit('update:open-burgerMenu', false); openBurgerMenu = false; burgerMenu = 1">На головну</div>
               <a href="#Faq" class="item" @click="() => {$emit('update:active-header', -1); $emit('update:current-page', 0); $emit('update:open-burgerMenu', false); openBurgerMenu = false}">FAQ</a>
               <div class="item"><a href="https://forum.secondliferp.com.ua/index.php" target="_blank">Форум</a></div>
               <a href="https://www.youtube.com/@SecondLifeRP_UA" class="item"><img src="../assets/img/youtubeP.svg" alt="" class="icon"> YouTube</a>
               <a href="https://www.tiktok.com/@secondliferp_ua" class="item"><img src="../assets/img/tiktokP.svg" alt="" class="icon"> TikTok</a>
               <a href="https://www.instagram.com/secondliferp_ua" class="item"><img src="../assets/img/instagramP.svg" alt="" class="icon"> Instagram</a>
               <a href="https://discord.gg/secondlife-roleplay-960651717156937728" class="item"><img src="../assets/img/discrodP.svg" alt="" class="icon"> Discord</a>
               <a href="https://t.me/SecondLifeRP" class="item"><img src="../assets/img/telegramP.svg" alt="" class="icon"> Telegram</a>
            </div>
         </div>
      </div>
   </div>
</template>
 
<script>
export default {
   name: "DonatePage",
   props: {
      currentPage: {
         type: Number,
         required: true,
      },
      activeHeader: {
         type: Number,
         required: true,
      },
      historyPosition: {
         type: Number,
         required: true,
      }
   },
   data() {
      return {
         burgerMenu: 0,
         firstCheck: false,
         secondCheck: false,
         scrolling: false,
         openBurgerMenu: false,
         login: '',
         money: '',
         percentAdd: null,
         lastClickInBtn: 0,
         incorrectLogin: false,
         incorrectMoney: false,
      };
   },
   methods:{
      ClickPage(hedpg,curpg) {
         if (this.burgerMenu == 0) {
            this.$emit('update:active-header', hedpg); 
            this.$emit('update:current-page', curpg)
            let position = this.historyPosition;
            if(curpg == 4){
               setTimeout(() => {
                  const anim = document.querySelector('.container').animate(
                  [
                     { transform: `translateY(${position}vh)`},
                     { transform: `translateY(${-100 * curpg}vh)`},
                  ], 
                  {
                     duration: 1000,
                     iterations: 1,
                     easing: 'ease-in-out',
                     effect: 'spring',
                     spring: { mass: 1, stiffness: 320, damping: 40 },
                  }
                  )
                  anim.addEventListener('finish', () => {
                     position = -100 * curpg
                     this.$emit("update:history-position", position);
                     document.querySelector('.container').style.transform = `translateY(${position}vh)`;
                  })
               }, 150);
            } 
         }
      },
      async PaymentsFondy() {
         if(new Date().getTime() - this.lastClickInBtn < 2500) return;
         if (this.login == '' || this.money == '' || !this.secondCheck) return;
         let count = parseInt(this.money.slice(1));
         if (count < 30) {
            this.incorrectMoney = true;
            this.money = 'Занадто мала сума. Мінімальна - 30 грн.';
            setTimeout(() => {
               this.incorrectMoney = false;
               this.money = ''
            }, 2000);
            return;
         }
         this.lastClickInBtn = new Date().getTime();
         
         await fetch("https://secondliferp.com.ua/donateFondy", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
               Login: this.login,
               Count: count
            })
         })
         .then((response) => {
            if(response.ok){
               return response.json();
            }

            if(response.status == 404){
               this.incorrectLogin = true;
               this.login = 'Такого гравця не існує. Перевірте введені дані.';
               setTimeout(() => {
                  this.incorrectLogin = false;
                  this.login = ''
               }, 2000);
            }else {
               this.incorrectLogin = true;
               this.login = 'Щось сталось не так.';
               setTimeout(() => {
                  this.incorrectLogin = false;
                  this.login = ''
               }, 2000);
            }
         })
         .then(data => {
            if(data == undefined) return;
            window.location.href = data.paymentUrl;
         })
         .catch((error) => {
           console.error(error);
         });
      },
      async PaymentsInterkassa() {
         if(new Date().getTime() - this.lastClickInBtn < 2500) return;
         if (this.login == '' || this.money == '' || !this.secondCheck) return;
         let count = parseInt(this.money.slice(1));
         this.lastClickInBtn = new Date().getTime();
         await fetch("https://secondliferp.com.ua/donateInterkassa", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
               Login: this.login,
               Count: count
            })
         })
         .then((response) => {
            if(response.ok){
               return response.json();
            }
            if(response.status == 404){
               this.incorrectLogin = true;
               this.login = 'Такого гравця не існує. Перевірте введені дані.';
               setTimeout(() => {
                  this.incorrectLogin = false;
                  this.login = ''
               }, 2000);
            }else {
               this.incorrectLogin = true;
               this.login = 'Щось сталось не так.';
               setTimeout(() => {
                  this.incorrectLogin = false;
                  this.login = ''
               }, 2000);
            }
         })
         .then(data => {
            if(data == undefined) return;
            //window.location.href = data.paymentUrl;
            const form = document.createElement("form");
            form.method = "POST";
            form.action = "https://sci.interkassa.com/";
            for (const key in data) {
               form.innerHTML += `<input type="text" name="${key}" value="${data[key]}">\n`
            }
            form.innerHTML += '<input type="submit" value="Pay">';
            document.body.appendChild(form);
            form.submit();
         })
         .catch((error) => {
           console.error(error);
         });
      },
      WidthUpdate() {
         if (window.innerWidth <= 744) this.burgerMenu = 1;
         else this.burgerMenu = 0
      },
      getOverflowStyle() {
         return {
            overflowY: this.burgerMenu == 0 || this.burgerMenu == 2 ? 'hidden' : 'scroll',
         };
      },
      CountPercent(count) {
         if (count < 2500) {this.percentAdd = null; return;}
         let typePercent = {
            0: 5, 
            1: 7,
            2: 10,
            3: 12
         }
         
         if (count >= 2500 && count <= 4999 ) {
            let formula = (Number(count) * typePercent[0]) / 100;
            this.percentAdd = Number(formula);
         } else if (count >= 5000 && count <= 9999) {
            let formula = (count * typePercent[1]) / 100 
            this.percentAdd = formula;
         } else if (count >= 10000 && count <= 19999) {
            let formula = (count * typePercent[2]) / 100 
            this.percentAdd = formula;
         } else if (count >= 20000) {
            let formula = (count * typePercent[3]) / 100 
            this.percentAdd = formula;
         }
      },
   },
   watch: {
      activeHeader(newValue) {
         if (newValue !== 1) {
            this.login = '';
            this.money = '';
            this.firstCheck = false;
            this.secondCheck = false;
         }
      },
   },
   created() {
      window.addEventListener("resize", this.WidthUpdate);
   },
   mounted() {
      this.WidthUpdate();
   },
};
</script>


<style lang="scss">
@import './nulstyle.scss';
@import './variables.scss';
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
.scrolldonate {
   height: 100vh;
   width: 100vw;
   overflow-x: hidden;
   &.scrolldonate::-webkit-scrollbar {
      width: 0;
      height: 0;
   }
   &.scrolldonate {
      scrollbar-width: none;
   }
   & > .donate {
      height: 100vh;
      width: 100vw;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: url(../assets/img/back/donate.jpg) no-repeat center center;
      background-size: cover;
      @media (max-width: 744px) {
         display: flex;
         align-items: center;
         justify-content: center;
         flex-direction: column;
         height: auto;
         background: url(../assets/img/mobile/back/donate.jpg) no-repeat center center;
         background-size: cover;
      }
      & > header {
         width: 100%;
         height: get-vh(160px);
         display: flex;
         align-items: center;
         justify-content: center;
         width: calc(100vw - #{get-vh(128px)});
         @media (max-width: 400px) { 
            width: calc(100vw - #{get-vh(64px)});
         }
         & > .container__hed {
            z-index: 2;
            height: 100%;
            width: get-vh(1680px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 1440px) { 
               width: get-vh(1280px);    
            }
            & > .logo img {
               position: relative;
               width: get-vh(240px);
               height: get-vh(88px);
               cursor: pointer;
               @media (max-width: 1440px) { 
                  width: get-vh(192px);
                  height: get-vh(70px);   
               }
            }
            & > .links {
               margin-left: get-vh(10px);
               flex: 1;
               max-width: get-vh(577px);
               height: get-vh(36px);
               display: flex;
               justify-content: space-between;
               @media (max-width: 1440px) { 
                  max-width: get-vh(461px);
                  height: get-vh(28px);
               }
               & > .page {
                  width: 50%;
                  height: 100%;
                  display: flex;
                  justify-content: space-between;
                  font-weight: 600;
                  font-size: get-vh(24px);
                  line-height: 125%;
                  letter-spacing: -0.03em;
                  text-shadow: 0px 0px get-vh(8px) rgba(0, 0, 0, 0.25);
                  cursor: pointer;
                  @media (max-width: 1440px) { 
                     font-size: get-vh(19px);
                  }
                  & > li > a {
                     color: #ffffff;
                  }
               }
               & > .social-icons {
                  width: 42%;
                  height: get-vh(36px);
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  @media (max-width: 1440px) { 
                     height: 100%;
                  }
                  & > li {
                     display: inline-block;
                     & > a {
                        box-shadow: 0px 0px get-vh(8px) rgba(0, 0, 0, 0.25);
                        border-radius: 50%;
                        height: get-vh(36px);
                        width: get-vh(36px);
                        display: block;
                        overflow: hidden;
                        @media (max-width: 1440px) { 
                           height: 100%;
                           width: get-vh(28px);
                        }
                        & > img {
                           display: block;
                           width: 100%;
                           height: 100%;
                        }
                     }
                  }
               }
            }
            & > .burger {
               width: get-vh(72px);
               height: get-vh(72px);
               position: relative;
               z-index: 2;
            }
         }
      }
      & > main {
         flex: 1 1 auto;
         max-width: get-vh(1920px);
         width: calc(100vw - #{get-vh(32px)});
         display: flex;
         align-items: center;
         justify-content: center;
         @media (max-width: 744px) { 
            width: calc(100vw - #{get-vh(128px)});
         }
         & > .font {
            height: get-vh(680px);
            width: get-vh(1680px);
            display: flex;
            justify-content: space-between;
            align-items: start;

            @media (max-width: 1440px) { 
               height: get-vh(544px);
               width: get-vh(1280px);    
            }
            @media (max-width: 1180px) { 
               height: get-vh(446px);
               width: get-vh(1050px);    
            }
            @media (max-width: 960px) { 
               height: get-vh(361px);
               width: get-vh(850px);    
            }
            @media (max-width: 744px) { 
               display: flex;
               flex-direction: column;
               height: auto;
            }
            & > .toup__left {
               width: get-vh(648px);
               display: flex;
               flex-direction: column;
               align-items: center;
               @media (max-width: 1440px) { 
                  width: get-vh(518px);   
               }
               @media (max-width: 1180px) { 
                  width: get-vh(424px);   
               }
               @media (max-width: 960px) { 
                  width: get-vh(344px);   
               }
               @media (max-width: 744px) { 
                  width: 100%;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: start;
               }
               & > .left__header {
                  width: 100%;
                  text-align: left;
                  font-size: get-vh(64px);
                  font-weight: 700;
                  letter-spacing: -0.01em;
                  @media (max-width: 1440px) { 
                     font-size: get-vh(50px);
                  }
                  @media (max-width: 1180px) { 
                     font-size: get-vh(42px);
                  }
                  @media (max-width: 960px) { 
                     font-size: get-vh(30px);
                  }
                  @media (max-width: 744px) { 
                     text-align: center;
                     font-size: get-vh(64px);
                     line-height: 110%;
                  }
               }
               & > .wrapper__countbonus {
                  margin-top: get-vh(29px);
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  @media (max-width: 1440px) { 
                     margin-top: get-vh(25px);
                  }
                  @media (max-width: 1180px) { 
                     margin-top: get-vh(20px);
                  }
                  @media (max-width: 960px) { 
                     margin-top: get-vh(15px);
                  }
                  @media (max-width: 744px) { 
                     flex-wrap: wrap;
                     text-align: center;
                     align-content: center;
                     justify-content: center;
                     gap: get-vh(30px);
                     margin-top: get-vh(25px);
                  }
                  & > .countbonus__text {
                     margin-left: get-vh(6px);
                     font-size: get-vh(21px);
                     font-weight: 600;
                     line-height: 1.2;
                     letter-spacing: -0.01em;
                     @media (max-width: 1440px) { 
                        margin-left: get-vh(4px);
                        font-size: get-vh(18px);
                     }
                     @media (max-width: 1180px) { 
                        margin-left: get-vh(3px);
                        font-size: get-vh(15px);
                     }
                     @media (max-width: 960px) { 
                        margin-left: get-vh(2px);
                        font-size: get-vh(11px);
                     }
                     @media (max-width: 744px) { 
                        font-size: get-vh(21px);
                     }
                  }
                  & > .wrapper__procentbonus {
                     margin-top: get-vh(-3px);
                     display: flex;
                     align-items: center;
                     gap: get-vh(16px);
                     @media (max-width: 1440px) { 
                        gap: get-vh(12px);
                     }
                     @media (max-width: 1180px) { 
                        gap: get-vh(10px);
                     }
                     @media (max-width: 960px) { 
                        gap: get-vh(8px);
                     }
                     @media (max-width: 744px) { 
                        flex-wrap: wrap;
                        gap: get-vh(16px);
                     }
                     & > .item__procentbonus {
                        text-align: center;
                        &.active {
                           color: #FFBF00;
                           & > .subtitle__bonus { 
                              color: rgba(255, 191, 0, 0.50);
                           }
                        }
                        & > .title__procent {
                           font-size: get-vh(28px);
                           @media (max-width: 1440px) { 
                              font-size: get-vh(22px);
                           }
                           @media (max-width: 1180px) { 
                              font-size: get-vh(18px);
                           }
                           @media (max-width: 960px) { 
                              font-size: get-vh(14px);
                           }
                           @media (max-width: 744px) { 
                              font-size: get-vh(28px);
                           }
                           font-style: italic;
                           font-weight: 700;
                           line-height: 1;
                           letter-spacing: -0.02em;
                           transition: color 0.25s ease-out;
                        }
                        & > .subtitle__bonus {
                           @media (max-width: 1440px) { 
                              margin-top: get-vh(4px);
                              font-size: get-vh(16px);
                           }
                           @media (max-width: 1180px) { 
                              margin-top: get-vh(3px);
                              font-size: get-vh(12px);
                           }
                           @media (max-width: 960px) { 
                              margin-top: get-vh(2px);
                              font-size: get-vh(10px);
                           }
                           @media (max-width: 744px) { 
                              font-size: get-vh(20px);
                           }
                           margin-top: get-vh(6px);
                           color: rgba(255, 255, 255, 0.50);
                           font-size: get-vh(20px);
                           font-weight: 600;
                           line-height: 1;
                           letter-spacing: -0.02em;
                           transition: color 0.25s ease-out;
                        }
                     }
                     & > .sep {
                        width: get-vh(3px);
                        height: get-vh(50px);
                        @media (max-width: 1440px) { 
                           height: get-vh(42px);
                        }
                        @media (max-width: 1180px) { 
                           height: get-vh(36px);
                        }
                        @media (max-width: 960px) { 
                           height: get-vh(30px);
                        }
                        flex-shrink: 0;
                        border-radius: get-vh(100px);
                        background: #FFF;
                     }
                  }
               }
               & > .wrapper__toup {
                  margin-top: get-vh(42px);
                  display: flex;
                  gap: get-vh(20px);
                  @media (max-width: 1440px) { 
                     margin-top: get-vh(36px);
                     gap: get-vh(16px);
                  }
                  @media (max-width: 1180px) { 
                     margin-top: get-vh(30px);
                     gap: get-vh(12px);
                  }
                  @media (max-width: 960px) { 
                     margin-top: get-vh(24px);
                     gap: get-vh(10px);
                  }
                  @media (max-width: 744px) { 
                     width: 100%;
                     display: flex;
                     flex-direction: column;
                     gap: get-vh(20px);
                  }
                  & > .login {
                     flex-shrink: 0;
                     width: get-vh(368px);
                     height: get-vh(72px);
                     padding: 0 get-vh(16px);
                     border-radius: get-vh(36px);
                     background: rgba(255, 255, 255, 0.12);
                     color: #FFF;
                     text-align: center;
                     font-size: get-vh(28px);
                     font-weight: 600;
                     letter-spacing: -0.01em;
                     &::placeholder {
                        color: rgba(255, 255, 255, 0.25);
                     }
                     @media (max-width: 1440px) { 
                        width: get-vh(300px);
                        height: get-vh(62px);
                        padding: 0 get-vh(12px);
                        border-radius: get-vh(30px);
                        font-size: get-vh(22px);
                     }
                     @media (max-width: 1180px) { 
                        width: get-vh(267px);
                        height: get-vh(50px);
                        padding: 0 get-vh(10px);
                        border-radius: get-vh(26px);
                        font-size: get-vh(20px);
                     }
                     @media (max-width: 960px) { 
                        width: get-vh(200px);
                        height: get-vh(42px);
                        padding: 0 get-vh(8px);
                        border-radius: get-vh(22px);
                        font-size: get-vh(18px);
                     }
                     @media (max-width: 744px) { 
                        width: 100%;
                        height: get-vh(72px);
                        font-size: get-vh(28px);
                     }
                  }
                  & > .left__countmoney {
                     flex-shrink: 0;
                     height: get-vh(72px);
                     width: get-vh(260px);
                     border-radius: get-vh(36px);
                     background: rgba(255, 255, 255, 0.12);
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     text-align: center;
                     color: #FFF;
                     font-size: get-vh(28px);
                     font-weight: 600;
                     letter-spacing: -0.01em;
                     &::placeholder {
                        color: rgba(255, 255, 255, 0.25);
                     }
                     @media (max-width: 1440px) { 
                        height: get-vh(62px);
                        width: get-vh(210px);
                        font-size: get-vh(24px);
                        border-radius: get-vh(30px);
                     }
                     @media (max-width: 1180px) { 
                        width: get-vh(160px);
                        height: get-vh(50px);
                        font-size: get-vh(20px);
                        border-radius: get-vh(26px);
                     }
                     @media (max-width: 960px) { 
                        width: get-vh(148px);
                        height: get-vh(42px);
                        font-size: get-vh(18px);
                        border-radius: get-vh(22px);
                     }
                     @media (max-width: 744px) { 
                        width: 100%;
                        height: get-vh(72px);
                        font-size: get-vh(28px);
                     }
                  }
               }
               & > .wrapper__course {
                  margin-top: get-vh(47px);
                  width: 100%;
                  display: flex;
                  align-items: start;
                  justify-content: space-between;
                  font-size: get-vh(28px);
                  font-weight: 600;
                  @media (max-width: 1440px) { 
                     margin-top: get-vh(37px);
                     font-size: get-vh(24px);
                  }
                  @media (max-width: 1180px) { 
                     margin-top: get-vh(30px);
                     font-size: get-vh(22px);
                  }
                  @media (max-width: 960px) { 
                     margin-top: get-vh(20px);
                     font-size: get-vh(20px);
                  }
                  @media (max-width: 744px) { 
                     text-align: center;
                     display: flex;
                     flex-direction: column;
                     align-items: center;
                     gap: get-vh(16px);
                     font-size: get-vh(28px);
                  }
                  & > .left__course {
                     margin-left: get-vh(10px);
                     display: flex;
                     align-items: center;
                     gap: get-vh(10px);
                     letter-spacing: -0.03em;
                     @media (max-width: 1440px) { 
                        margin-left: get-vh(8px);
                     }
                     @media (max-width: 1180px) { 
                        margin-left: get-vh(7px);
                     }
                     @media (max-width: 960px) { 
                        margin-left: get-vh(6px);
                     }
                     @media (max-width: 744px) { 
                        font-size: get-vh(28px);
                     }
                     & > .icon-coin {
                        width: get-vh(28px);
                        height: get-vh(28px);
                        @media (max-width: 1440px) { 
                           width: get-vh(24px);
                           height: get-vh(24px);
                        }
                        @media (max-width: 1180px) { 
                           width: get-vh(20px);
                           height: get-vh(20px);
                        }
                        @media (max-width: 960px) { 
                           width: get-vh(18px);
                           height: get-vh(18px);
                        }
                        @media (max-width: 744px) { 
                           width: get-vh(28px);
                           height: get-vh(28px);
                        }
                     }
                  }
                  & > .right__part { 
                     display: flex;
                     align-items: end;
                     flex-direction: column;
                     & > .count__taken {
                        display: flex;
                        align-items: center;
                        & > .count__take {
                           letter-spacing: -0.01em;
                        }
                        & > .icon-coin {
                           width: get-vh(28px);
                           height: get-vh(28px);
                           @media (max-width: 1440px) { 
                              width: get-vh(24px);
                              height: get-vh(24px);
                           }
                           @media (max-width: 1180px) { 
                              width: get-vh(20px);
                              height: get-vh(20px);
                           }
                           @media (max-width: 960px) { 
                              width: get-vh(18px);
                              height: get-vh(18px);
                           }
                           @media (max-width: 744px) { 
                              width: get-vh(28px);
                              height: get-vh(28px);
                           }
                        }
                     }
                     & > .activat__bonus {
                        color: #FFBF00;
                        font-size: get-vh(16px);
                        font-weight: 600;
                        letter-spacing: -0.01em;
                     }
                  }
               }
               & > .left__agreement {
                  margin-top: get-vh(44px);
                  display: flex;
                  align-items: center;
                  gap: get-vh(22px);
                  font-size: get-vh(22px);
                  font-weight: 600;
                  line-height: 1.27;
                  letter-spacing: -0.02em;
                  @media (max-width: 1440px) { 
                     margin-top: get-vh(34px);
                     gap: get-vh(20px);
                     font-size: get-vh(20px);
                  }
                  @media (max-width: 1180px) { 
                     margin-top: get-vh(28px);
                     gap: get-vh(16px);
                     font-size: get-vh(16px);
                  }
                  @media (max-width: 960px) { 
                     margin-top: get-vh(20px);
                     gap: get-vh(12px);
                     font-size: get-vh(12px);
                  }
                  @media (max-width: 744px) { 
                     margin-top: get-vh(8px);
                     width: 100%;
                     gap: get-vh(22px);
                     font-size: get-vh(26px);
                     display: flex;
                     align-items: center;
                     justify-content: center;
                  }
                  &.margin {
                     margin-top: get-vh(25px);
                  }
                  & > .circle__accept {
                     width: get-vh(48px);
                     height: get-vh(48px);
                     border-radius: get-vh(96px);
                     background: rgba(255, 255, 255, 0.12);
                     box-shadow: 0px get-vh(1.92px) get-vh(61.44px) 0px rgba(0, 0, 0, 0.18);
                     flex-shrink: 0;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     @media (max-width: 1440px) { 
                        width: get-vh(40px);
                        height: get-vh(40px);
                     }
                     @media (max-width: 1180px) { 
                        width: get-vh(34px);
                        height: get-vh(34px);
                     }
                     @media (max-width: 960px) { 
                        width: get-vh(30px);
                        height: get-vh(30px);
                     }
                     @media (max-width: 744px) { 
                        width: get-vh(48px);
                        height: get-vh(48px);
                     }
                     & > .icon-check {
                        width: get-vh(34.56px);
                        height: get-vh(34.56px);
                        @media (max-width: 1440px) { 
                           width: get-vh(30px);
                           height: get-vh(30px);
                        }
                        @media (max-width: 1180px) { 
                           width: get-vh(24px);
                           height: get-vh(24px);
                        }
                        @media (max-width: 960px) { 
                           width: get-vh(20px);
                           height: get-vh(20px);
                        }
                        @media (max-width: 744px) { 
                           width: get-vh(34.56px);
                           height: get-vh(34.56px);
                        }
                     }
                  }
               }
               & > .left__buy {
                  margin-top: get-vh(44px);
                  flex-shrink: 0;
                  width: 100%;
                  height: get-vh(72px);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: get-vh(36px);
                  background: rgba(255, 255, 255, 0.12);
                  color: #FFF;
                  font-size: get-vh(28px);
                  font-weight: 600;
                  letter-spacing: -0.02em;
                  transition: color 0.25s ease-out, background 0.25s ease-out, box-shadow 0.25s ease-out;
                  @media (max-width: 1440px) { 
                     margin-top: get-vh(34px);
                     border-radius: get-vh(30px);
                  }
                  @media (max-width: 1180px) { 
                     margin-top: get-vh(28px);
                     border-radius: get-vh(26px);
                  }
                  @media (max-width: 960px) { 
                     margin-top: get-vh(20px);
                     margin-bottom: get-vh(30px);
                     border-radius: get-vh(22px);
                  }
                  &.active {
                     background: #FFBF00;
                     color: #000;
                     &:hover {
                        box-shadow: 0px 0px get-vh(96px) get-vh(4px) rgba(255, 191, 0, 0.85);
                     }
                  }
                  &.red {
                     background: #E50000;
                     box-shadow: 0px 0px get-vh(96px) get-vh(4px) #E50000;
                  }
               }
            }
            & > .side__one {
               width: get-vh(648px);
               display: flex;
               flex-direction: column;
               justify-content: end;
               @media (max-width: 1440px) { 
                  width: get-vh(518px);   
               }
               @media (max-width: 1180px) { 
                  width: get-vh(424px);   
               }
               @media (max-width: 960px) { 
                  width: get-vh(344px);   
               }
               @media (max-width: 744px) {
                  width: 100%;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: start;
               }
               & > .label {
                  font-size: get-vh(64px);
                  font-weight: 700;
                  line-height: 1.25;
                  letter-spacing: -0.01em;
                  @media (max-width: 1440px) { 
                     font-size: get-vh(56px);
                  }
                  @media (max-width: 1180px) { 
                     font-size: get-vh(46px);
                  }
                  @media (max-width: 960px) { 
                     font-size: get-vh(37px);
                  }
                  @media (max-width: 744px) { 
                     font-size: get-vh(72px);
                     line-height: 110%;
                  }
               }
               & > .cnovert {
                  display: flex;
                  align-items: center;
                  margin-bottom: get-vh(36px);
                  font-weight: 600;
                  font-size: get-vh(32px);
                  line-height: 130%;
                  letter-spacing: -0.03em;  
                  & > .coin {
                     width: get-vh(32px);
                     height: get-vh(32px);
                     margin-left: get-vh(12px);
                  }  
                  @media (max-width: 1440px) { 
                     margin-bottom: get-vh(28px);
                     font-size: get-vh(25px);
                     line-height: 130%;
                     letter-spacing: -0.03em;  
                     & > .coin {
                        width: get-vh(25px);
                        height: get-vh(25px);
                        margin-left: get-vh(10px);
                     }  
                  }      
                  @media (max-width: 1180px) { 
                     margin-bottom: get-vh(23px);
                     font-size: get-vh(20px);
                     line-height: 130%;
                     letter-spacing: -0.03em;  
                     & > .coin {
                        width: get-vh(20px);
                        height: get-vh(20px);
                        margin-left: get-vh(8.2px);
                     }  
                  }    
                  @media (max-width: 960px) { 
                     margin-bottom: get-vh(21px);
                     font-size: get-vh(16px);
                     line-height: 130%;
                     letter-spacing: -0.03em;  
                     & > .coin {
                        width: get-vh(16px);
                        height: get-vh(16px);
                        margin-left: get-vh(6.6px);
                     }  
                  }   
                  @media (max-width: 744px) { 
                     margin-bottom: get-vh(36px);
                     font-size: get-vh(27px);
                     line-height: 130%;
                     letter-spacing: -0.03em;  
                     & > .coin {
                        width: get-vh(27px);
                        height: get-vh(27px);
                        margin-left: get-vh(10px);
                     } 
                  }
               }
               & > .login {
                  margin-bottom: get-vh(24px);
                  width: get-vh(648px);
                  height: get-vh(72px);
                  background: rgba(255, 255, 255, 0.2);
                  border-radius: get-vh(20px);
                  color: #ffffff;
                  font-weight: 500;
                  font-size: get-vh(24px);
                  letter-spacing: -0.03em;
                  padding: 0 get-vh(32px);
                  transition: background .25s ease;
                  &:focus {
                     background: rgba(255, 255, 255, 0.35);
                  }
                  &::placeholder {
                     color: rgba(255, 255, 255, 0.5);
                  }
                  &.incorrect {
                     border: get-vh(4px) solid #E40000;
                  }
                  @media (max-width: 1440px) { 
                     margin-bottom: get-vh(19px);
                     width: get-vh(518px);
                     height: get-vh(57px);
                     border-radius: get-vh(16px);
                     font-size: get-vh(19px);
                     padding: 0 get-vh(25px);
                  }
                  @media (max-width: 1180px) { 
                     margin-bottom: get-vh(15.5px);
                     width: get-vh(424px);
                     height: get-vh(46px);
                     border-radius: get-vh(13px);
                     font-size: get-vh(15.5px);
                     padding: 0 get-vh(20px);
                  }
                  @media (max-width: 960px) { 
                     margin-bottom: get-vh(12.5px);
                     width: get-vh(343px);
                     height: get-vh(37px);
                     border-radius: get-vh(10.5px);
                     font-size: get-vh(12.5px);
                     padding: 0 get-vh(16px);
                  }
                  @media (max-width: 744px) { 
                     margin-bottom: get-vh(24px);
                     width: 100%;
                     height: get-vh(72px);
                     border-radius: get-vh(24px);
                     font-size: get-vh(24px);
                     padding: 0 get-vh(27px);
                  }
               }
               & > .price {
                  margin-bottom: get-vh(32px);
                  width: get-vh(648px);
                  height: get-vh(72px);
                  background: rgba(255, 255, 255, 0.2);
                  border-radius: get-vh(20px);
                  color: #ffffff;
                  font-weight: 500;
                  font-size: get-vh(24px);
                  letter-spacing: -0.03em;
                  padding: 0 get-vh(32px);
                  transition: background .25s ease;
                  &.incorrect {
                     border: get-vh(4px) solid #E40000;
                  }
                  &:focus {
                     background: rgba(255, 255, 255, 0.35);
                  }
                  &::placeholder {
                     color: rgba(255, 255, 255, 0.5);
                  }
                  @media (max-width: 1440px) { 
                     margin-bottom: get-vh(19px);
                     width: get-vh(518px);
                     height: get-vh(57px);
                     border-radius: get-vh(16px);
                     font-size: get-vh(19px);
                     padding: 0 get-vh(25px);
                  }
                  @media (max-width: 1180px) { 
                     margin-bottom: get-vh(15.5px);
                     width: get-vh(424px);
                     height: get-vh(46px);
                     border-radius: get-vh(13px);
                     font-size: get-vh(15.5px);
                     padding: 0 get-vh(20px);
                  }
                  @media (max-width: 960px) { 
                     margin-bottom: get-vh(12.5px);
                     width: get-vh(343px);
                     height: get-vh(37px);
                     border-radius: get-vh(10.5px);
                     font-size: get-vh(12.5px);
                     padding: 0 get-vh(16px);
                  }
                  @media (max-width: 744px) { 
                     margin-bottom: get-vh(24px);
                     width: 100%;
                     height: get-vh(72px);
                     border-radius: get-vh(24px);
                     font-size: get-vh(24px);
                     padding: 0 get-vh(27px);
                  }
               }
               & > .check {
                  display: flex;
                  align-items: center;
                  margin-bottom: get-vh(24px);
                  font-weight: 500;
                  font-size: get-vh(24px);
                  line-height: 120%;
                  letter-spacing: -0.03em;
                  @media (max-width: 1440px) { 
                     margin-bottom: get-vh(19px);
                     font-weight: 500;
                     font-size: get-vh(19px);
                     line-height: 120%;
                     letter-spacing: -0.03em;
                  }
                  @media (max-width: 1180px) { 
                     margin-bottom: get-vh(15.5px);
                     font-weight: 500;
                     font-size: get-vh(15.5px);
                     line-height: 120%;
                     letter-spacing: -0.03em;
                  }
                  @media (max-width: 960px) { 
                     margin-bottom: get-vh(12.5px);
                     font-weight: 500;
                     font-size: get-vh(12.5px);
                     line-height: 120%;
                     letter-spacing: -0.03em;
                  }
                  @media (max-width: 744px) { 
                     margin-bottom: get-vh(24px);
                     font-weight: 500;
                     font-size: get-vh(24px);
                     line-height: 120%;
                     letter-spacing: -0.03em;  
                  }
                  & > .container__text {
                     width: 100%;
                     display: flex;
                     flex-direction: column;
                     justify-content: space-between;
                     font-weight: 500;
                     font-size: get-vh(24px);
                     line-height: 120%;
                     letter-spacing: -0.03em;  
                     & > a {
                        cursor: pointer;
                        color: #FFB800;
                     }
                  }
                  & > a {
                     cursor: pointer;
                     color: #FFB800;
                  }
                  & > .block {
                     cursor: pointer;
                     margin-right: get-vh(20px);
                     width: get-vh(48px);
                     height: get-vh(48px);
                     background: rgba(255, 255, 255, 0.2);
                     border-radius: get-vh(16px);
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     & > .check {
                        width: get-vh(36px);
                        height: get-vh(36px);
                        opacity: 0;
                        transition: opacity .25s ease;
                        &.visible {
                           opacity: 1;
                        }
                        &.hide {
                           overflow: 0;
                        }
                     }
                     @media (max-width: 1440px) { 
                        margin-right: get-vh(16px);
                        width: get-vh(38px);
                        height: get-vh(38px);
                        border-radius: get-vh(13px);
                        & > .check {
                           width: get-vh(28px);
                           height: get-vh(28px);
                           opacity: 0;
                           transition: opacity .25s ease;
                        }
                     }
                     @media (max-width: 1180px) { 
                        margin-right: get-vh(13px);
                        width: get-vh(31px);
                        height: get-vh(31px);
                        border-radius: get-vh(10.5px);
                        & > .check {
                           width: get-vh(23px);
                           height: get-vh(23px);
                           opacity: 0;
                           transition: opacity .25s ease;
                        }
                     }
                     @media (max-width: 960px) { 
                        margin-right: get-vh(10.5px);
                        width: get-vh(25px);
                        height: get-vh(25px);
                        border-radius: get-vh(8.5px);
                        & > .check {
                           width: get-vh(18px);
                           height: get-vh(18px);
                           opacity: 0;
                           transition: opacity .25s ease;
                        }
                     }
                     @media (max-width: 744px) {  
                        margin-right: get-vh(18px);
                        width: get-vh(48px);
                        height: get-vh(48px);
                        border-radius: get-vh(15px);
                        flex-shrink: 0;
                        & > .check {
                           width: get-vh(36px);
                           height: get-vh(36px);
                           opacity: 0;
                           transition: opacity .25s ease;
                        }
                     }
                     transition: background .25s ease;
                     &:hover {
                        background: rgba(255, 255, 255, 0.5);
                     }
                  }
               }
               & > .check__two {
                  margin-bottom: get-vh(32px);
                  display: flex;
                  align-items: center;
                  font-weight: 500;
                  font-size: get-vh(24px);
                  line-height: 120%;
                  letter-spacing: -0.03em;
                  @media (max-width: 1440px) { 
                     margin-bottom: get-vh(19px);
                     font-weight: 500;
                     font-size: get-vh(19px);
                     line-height: 120%;
                     letter-spacing: -0.03em;
                  }
                  @media (max-width: 1180px) { 
                     margin-bottom: get-vh(15.5px);
                     font-weight: 500;
                     font-size: get-vh(15.5px);
                     line-height: 120%;
                     letter-spacing: -0.03em;
                  }
                  @media (max-width: 960px) { 
                     margin-bottom: get-vh(12.5px);
                     font-weight: 500;
                     font-size: get-vh(12.5px);
                     line-height: 120%;
                     letter-spacing: -0.03em;
                  }
                  @media (max-width: 744px) { 
                     margin-bottom: get-vh(24px);
                     font-weight: 500;
                     font-size: get-vh(24px);
                     line-height: 120%;
                     letter-spacing: -0.03em;  
                     height: auto;
                  }
                  & > .container__text {
                     width: 100%;
                     display: flex;
                     flex-direction: column;
                     justify-content: space-between;
                     font-weight: 500;
                     font-size: get-vh(24px);
                     line-height: 120%;
                     letter-spacing: -0.03em;  
                     & > a {
                        cursor: pointer;
                        color: #FFB800;
                     }
                  }
                  & > a {
                     cursor: pointer;
                     color: #FFB800;
                  }
                  & > .block {
                     cursor: pointer;
                     margin-right: get-vh(20px);
                     width: get-vh(48px);
                     height: get-vh(48px);
                     background: rgba(255, 255, 255, 0.2);
                     border-radius: get-vh(16px);
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     & > .check {
                        width: get-vh(36px);
                        height: get-vh(36px);
                        opacity: 0;
                        transition: opacity .25s ease;
                        &.visible {
                           opacity: 1;
                        }
                        &.hide {
                           overflow: 0;
                        }
                     }
                     @media (max-width: 1440px) { 
                        margin-right: get-vh(16px);
                        width: get-vh(38px);
                        height: get-vh(38px);
                        border-radius: get-vh(13px);
                        & > .check {
                           width: get-vh(28px);
                           height: get-vh(28px);
                           opacity: 0;
                           transition: opacity .25s ease;
                           &.visible {
                              opacity: 1;
                           }
                           &.hide {
                              overflow: 0;
                           }
                        }
                     }
                     @media (max-width: 1180px) { 
                        margin-right: get-vh(13px);
                        width: get-vh(31px);
                        height: get-vh(31px);
                        border-radius: get-vh(10.5px);
                        & > .check {
                           width: get-vh(23px);
                           height: get-vh(23px);
                           opacity: 0;
                           transition: opacity .25s ease;
                        }
                     }
                     @media (max-width: 960px) { 
                        margin-right: get-vh(10.5px);
                        width: get-vh(25px);
                        height: get-vh(25px);
                        border-radius: get-vh(8.5px);
                        & > .check {
                           width: get-vh(18px);
                           height: get-vh(18px);
                           opacity: 0;
                           transition: opacity .25s ease;
                        }
                     }
                     @media (max-width: 744px) {  
                        margin-right: get-vh(18px);
                        width: get-vh(48px);
                        height: get-vh(48px);
                        border-radius: get-vh(15px);
                        flex-shrink: 0;
                        & > .check {
                           width: get-vh(36px);
                           height: get-vh(36px);
                           opacity: 0;
                           transition: opacity .25s ease;
                        }
                     }
                     transition: background .25s ease;
                     &:hover {
                        background: rgba(255, 255, 255, 0.5);
                     }
                  }
               }
               & > .btn__item {
                  width: 100%;
                  height: get-vh(72px);
                  background: rgba(255, 255, 255, 0.2);
                  border-radius: get-vh(20px);
                  font-weight: 600;
                  font-size: get-vh(24px);
                  line-height: 100%;
                  letter-spacing: -0.03em;
                  color: rgba(255, 255, 255, 0.5); 
                  transition: background-color 0.3s ease, color 0.3s ease;  
                  cursor: auto;
                  &:nth-child(2) {
                     width: get-vh(360px);
                  }
                  &.active {
                     cursor: pointer;
                     background: #FFFFFF;
                     color: #000;
                     transition: background-color 0.3s ease, color 0.3s ease;
                     &:hover {
                        background: #FFC633;
                     }
                  } 
                  &.incorrect {
                     color: #FFFFFF;
                     background: #E40000;
                  }  
                  @media (max-width: 1180px) { 
                     margin-bottom: get-vh(8px);
                     width: 100%;
                     font-size: get-vh(15.5px);
                     line-height: 100%;
                     height: get-vh(46px);
                     background: rgba(255, 255, 255, 0.2);
                     border-radius: get-vh(13px);
                  }   
                  @media (max-width: 960px) { 
                     margin-bottom: get-vh(6.5px);
                     width: 100%;
                     font-size: get-vh(12.5px);
                     line-height: 100%;
                     height: get-vh(37px);
                     background: rgba(255, 255, 255, 0.2);
                     border-radius: get-vh(10.5px);
                  }   
                  @media (max-width: 744px) { 
                     margin-bottom: get-vh(36px);
                     height: get-vh(72px);
                     width: 100%;
                     font-size: get-vh(24px);
                     line-height: 100%;
                     background: rgba(255, 255, 255, 0.2);
                     border-radius: get-vh(24px);
                  }     
               }
               // & > .wrapper__btn {
               //    display: grid;
               //    grid-template-columns: repeat(2, 1fr);
               //    grid-column-gap: get-vh(20px);
               //    & > .btn__item {
               //       width: get-vh(268px);
               //       height: get-vh(72px);
               //       background: rgba(255, 255, 255, 0.2);
               //       border-radius: get-vh(20px);
               //       font-weight: 600;
               //       font-size: get-vh(24px);
               //       line-height: 100%;
               //       letter-spacing: -0.03em;
               //       color: rgba(255, 255, 255, 0.5); 
               //       transition: background-color 0.3s ease, color 0.3s ease;  
               //       cursor: auto;
               //       &:nth-child(2) {
               //          width: get-vh(360px);
               //       }
               //       &.active {
               //          cursor: pointer;
               //          background: #FFFFFF;
               //          color: #000;
               //          transition: background-color 0.3s ease, color 0.3s ease;
               //          &:hover {
               //             background: #FFC633;
               //          }
               //       } 
               //       &.incorrect {
               //          color: #FFFFFF;
               //          background: #E40000;
               //       }  
               //       // @media (max-width: 1180px) { 
               //       //    margin-bottom: get-vh(8px);
               //       //    width: 100%;
               //       //    font-size: get-vh(15.5px);
               //       //    line-height: 100%;
               //       //    height: get-vh(46px);
               //       //    background: rgba(255, 255, 255, 0.2);
               //       //    border-radius: get-vh(13px);
               //       // }   
               //       // @media (max-width: 960px) { 
               //       //    margin-bottom: get-vh(6.5px);
               //       //    width: 100%;
               //       //    font-size: get-vh(12.5px);
               //       //    line-height: 100%;
               //       //    height: get-vh(37px);
               //       //    background: rgba(255, 255, 255, 0.2);
               //       //    border-radius: get-vh(10.5px);
               //       // }   
               //       // @media (max-width: 744px) { 
               //       //    margin-bottom: get-vh(36px);
               //       //    height: get-vh(72px);
               //       //    width: 100%;
               //       //    font-size: get-vh(24px);
               //       //    line-height: 100%;
               //       //    background: rgba(255, 255, 255, 0.2);
               //       //    border-radius: get-vh(24px);
               //       // }     
               //    }
               //    @media (max-width: 1440px) {  
               //       grid-column-gap: get-vh(16px);
               //       & > .btn__item { 
               //          height: get-vh(57px);
               //          width: get-vh(220px);
               //          border-radius: get-vh(16px);
               //          font-size: get-vh(19px);
               //          &:nth-child(2) {
               //             width: get-vh(280px);
               //          }
               //       }
               //    }
               //    @media (max-width: 1180px) { 
               //       grid-column-gap: get-vh(10px);
               //       & > .btn__item { 
               //          height: get-vh(47px);
               //          width: get-vh(180px);
               //          border-radius: get-vh(13px);
               //          font-size: get-vh(16px);
               //          &:nth-child(2) {
               //             width: get-vh(234px);
               //          }
               //       }
               //    }   
               //    @media (max-width: 980px) { 
               //       grid-column-gap: get-vh(8px);
               //       & > .btn__item { 
               //          height: get-vh(42px);
               //          width: get-vh(140px);
               //          border-radius: get-vh(12px);
               //          font-size: get-vh(15px);
               //          &:nth-child(2) {
               //             width: get-vh(194px);
               //          }
               //       }
               //    }   
               //    @media (max-width: 744px) { 
               //       grid-template-columns: repeat(1, 1fr);
               //       grid-row-gap: get-vh(24px);
               //       margin-bottom: get-vh(16px);
               //       & > .btn__item { 
               //          height: get-vh(72px);
               //          width: 100%;
               //          font-size: get-vh(24px);
               //          border-radius: get-vh(24px);
               //          &:nth-child(2) {
               //             width: 100%;
               //          }
               //       }
               //    }   
               // }
            }
            & > .side__two {
               height: 100%;
               width: get-vh(878px);
               display: flex;
               flex-direction: column;
               @media (max-width: 1440px) { 
                  width: get-vh(710px);    
               }
               @media (max-width: 1180px) { 
                  width: get-vh(582px);    
               }
               @media (max-width: 960px) { 
                  width: get-vh(471px);    
               }
               @media (max-width: 744px) { 
                  width: 100%;    
               }
               & > .top__img {
                  margin-bottom: get-vh(24px);
                  width: 100%;
                  height: get-vh(300px);
                  @media (max-width: 1440px) { 
                     margin-bottom: get-vh(22px);
                     height: get-vh(240px);
                  }
                  @media (max-width: 1180px) { 
                     margin-bottom: get-vh(18px);
                     height: get-vh(196px);
                  }
                  @media (max-width: 960px) { 
                     margin-bottom: get-vh(14.5px);
                     height: get-vh(158px);
                  }
                  @media (max-width: 744px) { 
                     display: none;
                  }
               }
               & > .text {
                  font-weight: 600;
                  font-size: get-vh(23px);
                  line-height: 120%;
                  letter-spacing: -0.03em;
                  margin-bottom: get-vh(10px);
                  & > .yellow {
                     color: #FFB800;
                  }
                  @media (max-width: 1440px) { 
                     font-size: get-vh(18px);
                     margin-bottom: get-vh(10px);
                  }
                  @media (max-width: 1180px) { 
                     font-size: get-vh(14px);
                     margin-bottom: get-vh(10px);
                  }
                  @media (max-width: 960px) { 
                     font-size: get-vh(11px);
                     margin-bottom: get-vh(8px);
                  }
                  @media (max-width: 744px) { 
                     font-size: get-vh(24px);
                     margin-bottom: get-vh(30px); 
                  }
                  &:nth-child(2),
                  &:nth-child(3) {
                     margin-bottom: get-vh(20px);
                     @media (max-width: 1180px) { 
                        margin-bottom: get-vh(16px);
                     }
                     @media (max-width: 960px) { 
                        margin-bottom: get-vh(13px);
                     }
                     @media (max-width: 744px) { 
                        margin-bottom: get-vh(30px); 
                     }
                  }
               }
            }
         }
      }
      & > footer {
         position: relative;
         background: rgba(0, 0, 0, 1);
         padding: get-vh(36px) 0;
         width: 100%;
         flex-shrink: 0;
         @media (max-width: 400px) { 
         padding: get-vh(24px) 0;
      }
      & > .foot__containermin {
         width: 100%;
         height: 100%;
         display: flex;
         flex-direction: column;
         align-items: center;
            & > .logo__text {
               width: get-vh(252px);
               height: get-vh(96px);
               @media (max-width: 400px) { 
                  width: get-vh(168px);
                  height: get-vh(64px);
               }
            }
            & > .few__text {
               margin-top: get-vh(30px);
               width: get-vh(457px);
               display: flex;
               justify-content: space-between;
               font-weight: 600;
               font-size: get-vh(27px);
               line-height: 100%;
               letter-spacing: -0.03em;
               & > .item > a {
                  color: #FFFFFF;
               }
               & > .sep {
                  width: 0px;
                  height: get-vh(27px);
                  border: get-vh(2px) solid #FFFFFF;
                  transform: rotate(-180deg);
               }
               @media (max-width: 400px) { 
                  margin-top: get-vh(20px);
                  width: get-vh(332px);
                  font-size: get-vh(18px);
                  & > .sep {
                     height: get-vh(18px);
                     border: get-vh(1.5px) solid #FFFFFF;
                  }
               }
            }
            & > .media {
               margin-top: get-vh(36px);
               width: 100%;
               height: get-vh(108px);
               display: flex;
               align-items: center;
               justify-content: space-around;
               background: rgba(255, 255, 255, 0.08);
               & > .social-icons {
                  display: flex;
                  gap: get-vh(45px);
                  height: get-vh(54px);
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  & > li {
                     display: inline-block;
                     & > a {
                        height: get-vh(54px);
                        width: get-vh(54px);
                        display: block;
                        overflow: hidden;
                        & > img {
                           display: block;
                           width: 100%;
                           height: 100%;
                           height: auto;
                        }
                     }
                  }
               }
               @media (max-width: 400px) { 
                  margin-top: get-vh(24px);
                  height: get-vh(72px);
                  & > .social-icons {
                     gap: get-vh(36px);
                     height: get-vh(36px);
                     & > li {
                        display: inline-block;
                        & > a {
                           height: get-vh(36px);
                           width: get-vh(36px);
                        }
                     }
                  }
               }
            }
            & > .maney__words {
               margin-top: get-vh(36px);
               width: get-vh(333px);
               height: get-vh(120px);
               display: flex;
               flex-direction: column;
               text-align: center;
               align-items: center;
               justify-content: space-between;
               & > .item {
                  font-weight: 600;
                  font-size: get-vh(24px);
                  line-height: 100%;
                  letter-spacing: -0.03em;
                  color: #FFFFFF;
                  & > a {
                     color: #FFFFFF;
                  }
               }
               @media (max-width: 400px) { 
                  margin-top: get-vh(24px);
                  width: get-vh(222px);
                  height: get-vh(80px);
                  & > .item {
                     font-size: get-vh(16px);
                  }
               }
            }
            & > .mini__textlover {
               text-align: center;
               margin: get-vh(36px) 0;
               width: get-vh(272px);
               font-weight: 600;
               font-size: get-vh(12px);
               line-height: 120%;
               letter-spacing: -0.02em;
               color: rgba(255, 255, 255, 0.6);
               & > span {
                  font-size: get-vh(8px);
                  line-height: 120%;
                  letter-spacing: -0.05em;
               }
               @media (max-width: 400px) { 
                  margin: get-vh(28px) 0;
                  width: get-vh(240px);
                  font-weight: 600;
                  font-size: get-vh(11px);
                  & > span {
                     font-size: get-vh(7px);
                  }
               }
            }
         }
      }
      & > .open__burgermenu {
         width: 100%;
         height: 100%;
         z-index: 1;
         position: absolute;
         top: 0;
         left: 0;
         backdrop-filter: blur(get-vh(12px));
         background: rgba(0, 0, 0, 0.5);
         animation: openstart .5s ease-out;
         @keyframes openstart {
            0% {
               background: rgba(0, 0, 0, 0);
               backdrop-filter: blur(get-vh(0px));
            }
            100% {
               background: rgba(0, 0, 0, 0.5);
               backdrop-filter: blur(get-vh(12px));
            }
         }
         display: flex;
         flex-direction: column;
         align-items: center;
         padding-bottom: get-vh(40px);
         & > .list__social {
            height: get-vh(938px);
            width: get-vh(275px);
            margin-top: get-vh(124px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: get-vh(93px) 0;
            & > .item {
               font-weight: 600;
               font-size: get-vh(42px);
               line-height: 125%;
               letter-spacing: -0.03em;
               color: #FFFFFF;
               & > a {
                  color: #FFFFFF;
               }
               &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                  line-height: get-vh(48px);
                  align-items: center;
                  & > .icon {
                     width: get-vh(48px);
                     height: get-vh(48px);
                  }
               }
            }
         }
      }
   }
}


</style>