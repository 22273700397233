<template>
   <div class="first__screen">
      <header>
         <div class="container__hed">
            <div class="logo">
               <img src="../../src/assets/img/logoMini.png" alt="Logo" draggable="false">
            </div>
            <div class="links" v-if="burgerMenu == 0">
               <ul class="page">
                  <li :style="activeHeader === 0 ? 'color: #FFB800;' : 'color: #FFFFFF;'" @click="ClickPage(4)">FAQ</li>
                  <li><a href="https://forum.secondliferp.com.ua/index.php" target="_blank">Форум</a></li>
                  <li :style="activeHeader === 2 ? 'color: #FFB800;' : 'color: #FFFFFF;'" @click="showLoginDialog()">Донат</li>
               </ul>
               <ul class="social-icons">
                  <li><a href="https://discord.gg/secondlife-roleplay-960651717156937728" target="_blank"><img src="../../src/assets/img/discrod.svg" alt="discrod" draggable="false"></a></li>
                  <li><a href="https://t.me/SecondLifeRP" target="_blank"><img src="../../src/assets/img/telegram.svg" alt="telegram" draggable="false"></a></li>
                  <li><a href="https://www.youtube.com/@SecondLifeRP_UA" target="_blank"><img src="../../src/assets/img/youtube.svg" alt="yt" draggable="false"></a></li>
                  <li><a href="https://www.instagram.com/secondliferp_ua" target="_blank"><img src="../../src/assets/img/instagram.svg" alt="inst" draggable="false"></a></li>
                  <li><a href="https://www.tiktok.com/@secondliferp_ua" target="_blank"><img src="../../src/assets/img/tiktok.svg" alt="tiktok" draggable="false"></a></li>
               </ul>
            </div>
            <img src="../assets/img/menu.svg" alt="" class="burger" v-if="burgerMenu == 1" @click="() => {$emit('update:open-burgerMenu', true);openBurgerMenu = true; burgerMenu = 2}">
            <img src="../assets/img/close.svg" alt="" class="burger" v-if="burgerMenu == 2" @click="() => {$emit('update:open-burgerMenu', false);openBurgerMenu = false; burgerMenu = 1}">
         </div>
      </header>
      <main>
         <div class="font" :class="{column: burgerMenu == 1 || burgerMenu == 2}">
            <div class="font__left" v-if="burgerMenu == 0">
               <h1 class="title">Знайди своє друге життя на SecondLife RP</h1>
               <h2 class="sub__title">Зараз онлайн {{online.count}} гравці. Чому ти досі не з нами?</h2>
               <button @click="ClickPage(3)" v-if="burgerMenu == 0">Почати гру!</button>
               <a href="#StartGame" class="btnphone" v-if="burgerMenu == 1 || burgerMenu == 2">Почати гру!</a>
            </div>
            <h1 class="title" v-if="burgerMenu == 1 || burgerMenu == 2">Знайди своє друге життя на SecondLife RP</h1>
            <iframe class="video" src="https://www.youtube.com/embed/ZXeSLehz-88?si=OdcHb7RQt4A6mOJD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h2 class="sub__title" v-if="burgerMenu == 1 || burgerMenu == 2">Зараз онлайн {{online.count}} гравці. Чому ти досі не з нами?</h2>
            <a href="#StartGame" class="btnphone" v-if="burgerMenu == 1 || burgerMenu == 2">Почати гру!</a>
         </div>
      </main>
      <footer >
         <h2 class="subtitle">Гортай вниз, аби дізнатися більше!</h2>
         <img src="../../src/assets/img/arrow_down.svg" alt="" class="arrow" draggable="false">
      </footer>
      <div class="open__burgermenu" v-show="openBurgerMenu">
         <div class="list__social">
            <a href="#Faq" class="item" @click="() => {$emit('update:open-burgerMenu', false);openBurgerMenu = false; burgerMenu = 1}">FAQ</a>
            <div class="item"><a href="https://forum.secondliferp.com.ua/index.php" target="_blank">Форум</a></div>
            <div class="item" @click="$emit('update:current-page', -1); $emit('update:active-header', 1)">Донат</div>
            <a href="https://www.youtube.com/@SecondLifeRP_UA" class="item"><img src="../assets/img/youtubeP.svg" alt="" class="icon"> YouTube</a>
            <a href="https://www.tiktok.com/@secondliferp_ua" class="item"><img src="../assets/img/tiktokP.svg" alt="" class="icon"> TikTok</a>
            <a href="https://www.instagram.com/secondliferp_ua" class="item"><img src="../assets/img/instagramP.svg" alt="" class="icon"> Instagram</a>
            <a href="https://discord.gg/secondlife-roleplay-960651717156937728" class="item"><img src="../assets/img/discrodP.svg" alt="" class="icon"> Discord</a>
            <a href="https://t.me/SecondLifeRP" class="item"><img src="../assets/img/telegramP.svg" alt="" class="icon"> Telegram</a>
         </div>
      </div>
   </div>
 </template>
 
<script>
export default {
   name: "FirstPage",
   props: {
      currentPage: {
         type: Number,
         required: true,
      },
      online:{
         type: Object,
         require: true
      },
      historyPosition: {
         type: Number,
         required: true,
      }
   },
   data() {
      return {
         activeHeader: -1,
         burgerMenu: 0,
         openBurgerMenu: false
      };
   },
   methods: {
      ClickPage(page) {
         this.$emit('update:current-page', page)
         let position = this.historyPosition;
         if (this.openBurgerMenu == false) {
            const anim = document.querySelector('.container').animate(
            [
               { transform: `translateY(${position}vh)`},
               { transform: `translateY(${-100 * page}vh)`},
            ], 
            {
               duration: 800,
               iterations: 1,
               easing: 'ease-in-out',
               effect: 'spring',
               spring: { mass: 1, stiffness: 320, damping: 40 },
            }
            )
            anim.addEventListener('finish', () => {
               position = -100 * page
               this.$emit("update:history-position", position);
               document.querySelector('.container').style.transform = `translateY(${position}vh)`;
            })
         } else {
            this.openBurgerMenu = false; 
            setTimeout(() => {
               const anim = document.querySelector('.container').animate(
               [
                  { transform: `translateY(${position}vh)`},
                  { transform: `translateY(${-100 * page}vh)`},
               ], 
               {
                  duration: 800,
                  iterations: 1,
                  easing: 'ease-in-out',
                  effect: 'spring',
                  spring: { mass: 1, stiffness: 320, damping: 40 },
               }
               )
               anim.addEventListener('finish', () => {
                  position = -100 * page
                  this.$emit("update:history-position", position);
                  document.querySelector('.container').style.transform = `translateY(${position}vh)`;
               })
            }, 150)
         }
      },
      WidthUpdate() {
         if (window.innerWidth <= 744) this.burgerMenu = 1;
         else this.burgerMenu = 0
      },
      showLoginDialog() {
         this.$emit('update:current-page', -1);
         this.$emit('update:active-header', 1);
      }
   },
   created() {
      window.addEventListener("resize", this.WidthUpdate);
   },
   mounted() {
      this.WidthUpdate();
   },
};
</script>


<style lang="scss">
@import './nulstyle.scss';
@import './variables.scss';
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
$mouse-device: '(hover: hover) and (pointer: fine)';
.first__screen {
   height: 100vh;
   width: 100vw;
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background: url(../assets/img/back/slide5.jpg) no-repeat center center;
   background-size: cover;
   @media (max-width: 744px) {
      background: url(../assets/img/mobile/back/slide1.jpg) no-repeat center center;
      background-size: cover;
      overflow-x: hidden;
   }
   & > header {
      width: 100%;
      height: get-vh(160px);
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100vw - #{get-vh(128px)});
      @media (max-width: 400px) { 
         width: calc(100vw - #{get-vh(64px)});
      }
      & > .container__hed {
         z-index: 2;
         height: 100%;
         width: get-vh(1680px);
         display: flex;
         align-items: center;
         justify-content: space-between;
         @media (max-width: 1440px) { 
            width: get-vh(1280px);    
         }
         & > .logo img {
            position: relative;
            width: get-vh(240px);
            height: get-vh(88px);
            cursor: pointer;
            @media (max-width: 1440px) { 
               width: get-vh(192px);
               height: get-vh(70px);   
            }
         }
         & > .links {
            margin-left: get-vh(10px);
            flex: 1;
            max-width: get-vh(577px);
            height: get-vh(36px);
            display: flex;
            justify-content: space-between;
            @media (max-width: 1440px) { 
               max-width: get-vh(461px);
               height: get-vh(28px);
            }
            & > .page {
               width: 50%;
               height: 100%;
               display: flex;
               justify-content: space-between;
               font-weight: 600;
               font-size: get-vh(24px);
               line-height: 125%;
               letter-spacing: -0.03em;
               text-shadow: 0px 0px get-vh(8px) rgba(0, 0, 0, 0.25);
               cursor: pointer;
               color: #FFFFFF;
               & > li > a {
                  color: #FFFFFF;
               }
               @media (max-width: 1440px) { 
                  font-size: get-vh(19px);
               }
            }
            & > .social-icons {
               width: 42%;
               height: get-vh(36px);
               display: flex;
               align-items: center;
               justify-content: space-between;
               @media (max-width: 1440px) { 
                  height: 100%;
               }
               & > li {
                  display: inline-block;
                  & > a {
                     box-shadow: 0px 0px get-vh(8px) rgba(0, 0, 0, 0.25);
                     border-radius: 50%;
                     height: get-vh(36px);
                     width: get-vh(36px);
                     display: block;
                     overflow: hidden;
                     @media (max-width: 1440px) { 
                        height: 100%;
                        width: get-vh(28px);
                     }
                     & > img {
                        display: block;
                        width: 100%;
                        height: 100%;
                     }
                  }
               }
            }
         }
         & > .burger {
            width: get-vh(72px);
            height: get-vh(72px);
            position: relative;
            z-index: 2;
         }
      }
   }
   & > main {
      flex: 1 1 auto;
      max-width: get-vh(1920px);
      width: calc(100vw - #{get-vh(192px)});
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 400px) { 
         width: calc(100vw - #{get-vh(48px)});
      }
      & > .font {
         height: get-vh(412px);
         width: get-vh(1680px);
         display: flex;
         justify-content: space-between;
         align-items: center;
         @media (max-width: 1440px) { 
            height: get-vh(330px);
            width: get-vh(1280px);
         }
         @media (max-width: 1180px) { 
            height: get-vh(270px);
            width: get-vh(1050px);
         }
         @media (max-width: 820px) { 
            height: get-vh(190px);
            width: get-vh(735px);
         }
         @media (max-width: 744px) { 
            height: auto;
            width: get-vh(528px);
         }
         @media (max-width: 400px) { 
            height: auto;
            width: get-vh(375px);
         }
         & > .font__left {
            display: flex;
            flex-direction: column;
            width: get-vh(600px);
            gap: get-vh(40px);
            & > .title {
               font-size: get-vh(72px);
               font-weight: 700;
               line-height: 120%;
               letter-spacing: -0.015em;
               text-wrap: balance;
               @media (max-width: 1440px) { 
                  font-size: get-vh(64px);
               }
               @media (max-width: 1180px) { 
                  font-size: get-vh(58px);
               }
               @media (max-width: 820px) { 
                  font-size: get-vh(52px);
               }
            }
            & > .sub__title {
               font-size: get-vh(32px);
               font-weight: 600;
               line-height: 125%;
               letter-spacing: -0.02em;
               text-wrap: balance;
               @media (max-width: 1440px) { 
                  font-size: get-vh(26px);
               }
               @media (max-width: 1180px) { 
                  font-size: get-vh(24px);
               }
               @media (max-width: 820px) { 
                  font-size: get-vh(22px);
               }
            }
            & > button {
               max-width: get-vh(292px);
               padding: get-vh(20px) get-vh(48px);
               border-radius: get-vh(24px);
               background: #FFF;
               color: #000;
               font-size: get-vh(32px);
               font-weight: 600;
               letter-spacing: -0.01em;
               transition: background 0.25s ease, box-shadow 0.25s;
               &:hover {
                  background: #FFC233;
                  box-shadow: 0px get-vh(2px) get-vh(64px) 0px rgba(0, 0, 0, 0.25), 0px 0px get-vh(96px) get-vh(4px) rgba(255, 194, 51, 0.85);
               }
               @media (max-width: 1440px) { 
                  font-size: get-vh(26px);
               }
               @media (max-width: 1180px) { 
                  font-size: get-vh(24px);
               }
               @media (max-width: 820px) { 
                  font-size: get-vh(22px);
               }
            }
         }
         & > .video {
            width: get-vh(870px);
            height: get-vh(490px);
            border-radius: get-vh(32px);
            box-shadow: 0px 0px get-vh(64px) 0px rgba(0, 0, 0, 0.50);
            @media (max-width: 1440px) { 
               width: get-vh(700px);
               height: get-vh(400px);
            }
            @media (max-width: 1180px) { 
               width: get-vh(600px);
               height: get-vh(350px);
               border-radius: get-vh(24px);
            }
            @media (max-width: 820px) { 
               width: get-vh(500px);
               height: get-vh(300px);
               border-radius: get-vh(22px);
            }
            @media (max-width: 744px) { 
               width: get-vh(504px);
               height: get-vh(285px);
               border-radius: get-vh(22px);
            }
            @media (max-width: 400px) { 
               width: get-vh(336px);
               height: get-vh(190px);
               border-radius: get-vh(12px);
            }
         }
         &.column {
            display: flex;
            flex-direction: column;
            gap: get-vh(36px);
            @media (max-width: 400px) { 
               gap: get-vh(30px);
            }
            & > .title {
               text-align: center;
               font-size: clamp(1.75rem, 0.02rem + 8.24vw, 4.5rem);
               font-weight: 700;
               line-height: 120%;
               letter-spacing: -0.015em;
               text-wrap: balance;
               @media (max-width: 744px) { 
                  font-size: get-vh(42px);
               }
               @media (max-width: 400px) { 
                  font-size: get-vh(28px);
               }
            }
            & > .sub__title {
               text-align: center;
               font-size: clamp(1.25rem, 0.778rem + 2.25vw, 2rem);
               font-weight: 600;
               line-height: 125%;
               letter-spacing: -0.02em;
               text-wrap: balance;
               @media (max-width: 744px) { 
                  font-size: get-vh(30px);
               }
               @media (max-width: 400px) { 
                  font-size: get-vh(20px);
               }
            }
            & > a {
               width: get-vh(220px);
               height: get-vh(57px);
               border-radius: get-vh(20px);
               background: #FFF;
               display: flex;
               align-items: center;
               justify-content: center;
               color: #000;
               font-size: clamp(1.25rem, 1.114rem + 0.68vw, 1.625rem);;
               font-weight: 600;
               letter-spacing: -0.01em;
               transition: background 0.25s ease, box-shadow 0.25s;
               &:hover {
                  background: #FFC233;
                  box-shadow: 0px get-vh(2px) get-vh(64px) 0px rgba(0, 0, 0, 0.25), 0px 0px get-vh(96px) get-vh(4px) rgba(255, 194, 51, 0.85);
               }
            }
         }
         // & > .font__left {
            
         // }
         // @media (max-width: 1440px) { 
         //    height: get-vh(330px);
         //    width: get-vh(1280px);
         // }
         // @media (max-width: 1180px) { 
         //    height: get-vh(270px);
         //    width: get-vh(1050px);
         // }
         // @media (max-width: 820px) { 
         //    height: get-vh(190px);
         //    width: get-vh(735px);
         // }
         // @media (max-width: 744px) { 
         //    height: auto;
         //    width: get-vh(528px);
         // }
         // @media (max-width: 400px) { 
         //    height: auto;
         //    width: get-vh(375px);
         // }
         // & > .title {
         //    font-weight: 600;
         //    font-size: get-vh(72px);
         //    line-height: 120%;
         //    display: flex;
         //    align-items: center;
         //    text-align: center;
         //    letter-spacing: -0.015em;
         //    color: #ffffff;
         //    text-shadow: 0px 0px get-vh(64px) rgba(0, 0, 0, 0.25);
         //    @media (max-width: 1440px) { 
         //       font-size: get-vh(57.6px);
         //    }
         //    @media (max-width: 1180px) { 
         //       font-size: get-vh(52px);
         //    }
         //    @media (max-width: 820px) { 
         //       font-size: get-vh(36px);
         //    }
         //    @media (max-width: 744px) { 
         //       font-size: get-vh(63px);
         //    }
         //    @media (max-width: 400px) { 
         //       font-size: get-vh(42px);
         //    }
         // }
         // & > .qwe {
         //    width: get-vh(870px);
         //    height: get-vh(490px);
         // }
         // & > .sub__title {
         //    font-weight: 600;
         //    font-size: get-vh(36px);
         //    line-height: 125%;
         //    letter-spacing: -0.02em;
         //    color: #ffffff;
         //    text-shadow: 0px 0px get-vh(24px) rgba(0, 0, 0, 0.35);
         //    @media (max-width: 1440px) { 
         //       font-size: get-vh(29px);
         //    }
         //    @media (max-width: 1180px) { 
         //       font-size: get-vh(24px);
         //    }
         //    @media (max-width: 820px) { 
         //       font-size: get-vh(17px);
         //    }
         //    @media (max-width: 744px) { 
         //       margin-top: get-vh(15px);
         //       font-size: get-vh(33px);
         //    }
         //    @media (max-width: 400px) { 
         //       margin-top: get-vh(20px);
         //       font-size: get-vh(22px);
         //    }
         // }
         // & > .btnphone {
         //    margin-top: get-vh(15px);
         //    width: get-vh(330px);
         //    height: get-vh(97.5px);
         //    background: rgba(255, 255, 255, 0.25);
         //    box-shadow: 0px 0px get-vh(8px) rgba(0, 0, 0, 0.25);
         //    backdrop-filter: blur(get-vh(8px));
         //    border-radius: get-vh(20px);
         //    font-weight: 600;
         //    font-size: get-vh(31px);
         //    line-height: 125%;
         //    letter-spacing: -0.01em;
         //    display: flex;
         //    align-items: center;
         //    justify-content: center;
         //    color: #ffffff;
         //    flex-shrink: 0;
         //    transition: background 0.3s, color 0.3s ease-in-out;
         //    &:hover {
         //       @media #{$mouse-device} {
         //          transition: background 0.3s, color 0.3s ease-in-out;
         //          background: #ffffff;
         //          color: #000;
         //       }
         //    } 
         //    @media (max-width: 400px) { 
         //       margin-top: get-vh(20px);
         //       width: get-vh(220px);
         //       height: get-vh(65px);
         //       border-radius: get-vh(20px);
         //       font-size: get-vh(21px);
         //    }
         // }
         // & > button {
         //    width: get-vh(292px);
         //    height: get-vh(80px);
         //    background: rgba(255, 255, 255, 0.25);
         //    box-shadow: 0px 0px get-vh(8px) rgba(0, 0, 0, 0.25);
         //    backdrop-filter: blur(get-vh(8px));
         //    border-radius: get-vh(20px);
         //    font-weight: 600;
         //    font-size: get-vh(32px);
         //    line-height: 125%;
         //    letter-spacing: -0.01em;
         //    color: #ffffff;
         //    flex-shrink: 0;
         //    transition: background 0.3s, color 0.3s ease-in-out;
         //    &:hover {
         //       @media #{$mouse-device} {
         //          transition: background 0.3s, color 0.3s ease-in-out;
         //          background: #ffffff;
         //          color: #000;
         //       }
         //    }
         //    @media (max-width: 1440px) { 
         //       width: get-vh(234px);
         //       height: get-vh(64px);
         //       font-size: get-vh(25px);
         //       border-radius: get-vh(16px);
         //    } 
         //    @media (max-width: 1180px) { 
         //       width: get-vh(192px);
         //       height: get-vh(52px);
         //       font-size: get-vh(20px);
         //       border-radius: get-vh(13px);
         //    }
         //    @media (max-width: 820px) { 
         //       width: get-vh(134px);
         //       height: get-vh(36px);
         //       font-size: get-vh(14px);
         //       border-radius: get-vh(9px);
         //    }
         // }
      }
   }
   & > footer {
      width: get-vh(1680px);
      height: get-vh(160px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & > .subtitle {
         font-weight: 600;
         font-size: get-vh(32px);
         line-height: 125%;
         letter-spacing: -0.02em;
         text-shadow: 0px 0px get-vh(8px) rgba(0, 0, 0, 0.25);
         @media (max-width: 1440px) { 
            font-size: get-vh(25px);
         }
         @media (max-width: 400px) { 
            font-size: get-vh(18px);
         }
      }
      & > .arrow {
         width: get-vh(72px);
         height: get-vh(72px);
         @media (max-width: 1440px) { 
            width: get-vh(57.6px);
            height: get-vh(57.6px);
         }
         @media (max-width: 400px) { 
            width: get-vh(48px);
            height: get-vh(36px);
         }
      }
      @media (max-width: 744px) { 
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: end;
      }
   }
   & > .open__burgermenu {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      backdrop-filter: blur(get-vh(12px));
      background: rgba(0, 0, 0, 0.5);
      animation: openstart .5s ease-out;
      @keyframes openstart {
         0% {
            background: rgba(0, 0, 0, 0);
            backdrop-filter: blur(get-vh(0px));
         }
         100% {
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(get-vh(12px));
         }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: get-vh(40px);
      & > .list__social {
         height: 100%;
         width: get-vh(275px);
         margin-top: get-vh(124px);
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         align-items: center;
         padding: get-vh(93px) 0;
         & > .item {
            font-weight: 600;
            font-size: get-vh(42px);
            line-height: 125%;
            letter-spacing: -0.03em;
            color: #FFFFFF;
            & > a {
               color: #FFFFFF;
            }
            &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
               line-height: get-vh(48px);
               align-items: center;
               & > .icon {
                  width: get-vh(48px);
                  height: get-vh(48px);
               }
            }
         }
      }
   }
}
</style>