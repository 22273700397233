<template>
   <ul class="scroll" v-if="hideScroll">
    <li
      class="circle"
      v-for="(circle, index) in 5"
      :key="index"
      :class="{ active: currentPage === index }"
      @click="ClickPage(index)"
    ></li>
  </ul>
</template>

<script>
export default {
   name: "ScrollItem",
   props: {
      currentPage: {
         type: Number,
         required: true,
      },
      historyPosition: {
         type: Number,
         required: true,
      }
   },
   data() {
      return {
         hideScroll: true,
      };
   },
   methods: {
      ClickPage(ind) {
         this.$emit("update:current-page", ind);
         let position = this.historyPosition;
         let duration = 400;

         if ((ind === 4 && this.currentPage === 0) || (ind === 0 && this.currentPage === 4)) {
            duration = 800;
         }
         const anim = document.querySelector('.container').animate(
         [
            { transform: `translateY(${position}vh)`},
            { transform: `translateY(${-100 * ind}vh)`},
         ], 
         {
            duration: duration,
            iterations: 1,
            easing: 'ease-in-out',
            effect: 'spring',
            spring: { mass: 1, stiffness: 320, damping: 40 },
         }
         )
         anim.addEventListener('finish', () => {
            position = -100 * ind
            this.$emit("update:history-position", position);
            document.querySelector('.container').style.transform = `translateY(${position}vh)`;
         })
      },
      WidthUpdate() {
         if (window.innerWidth <= 744) this.hideScroll = false;
         else this.hideScroll = true
      },
   },
   created() {
      window.addEventListener("resize", this.WidthUpdate);
   },
   mounted() {
      this.WidthUpdate();
   },
};
</script>
<style lang="scss">
@import './nulstyle.scss';
@import './variables.scss';
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
.scroll {
   z-index: 5;
   position: fixed;
   right: get-vh(32px);
   top: 50%;
   transform: translateY(-50%);
   width: get-vh(32px);
   height: get-vh(256px);
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   & > .circle {
      cursor: pointer;
      border-radius: 50%;
      width: get-vh(32px);
      height: get-vh(32px);
      border: get-vh(4px) solid #ffffff;
      transition: all 0.5s ease 0.1s;
      &.circle.active {
         background-color: #ffffff;
         transform: scale(1);
      }
   }
}
</style>